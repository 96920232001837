@import "../../../scss/variables";

.overideSearchInputField {
  .icon {
    color: #999999;
  }
  .input_field_box{

  }
  .formControlRoot {
    background: $white-color;
    border: 1px solid #caced8;
    border-radius: 8px;
    font-family: $font-Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
  }
  
  .inputRoot {
    input{
    height: 22px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #083a50;
  }
  ::placeholder{
    font-family: $font-Montserrat;
    color: $text-black-color;
    font-weight: 400;
    opacity: 1;
  }
  // background: black;
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 8.5px 15px;
    // height: 50px;
    &.Mui-disabled {
      background-color: $fog-grey;
      height: 80px;
    }
  }
  
  input:active,
  input:focus {
    // color: #043133;
    // font-family: $font-Montserrat;
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
    left: 0;
  }
}

[data-theme="dark"] {
  color: $white-color;
  .overideInputField {
    color: $white-color;
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      .inputRoot {
        color: $white-color;
        opacity: 1;
        &.Mui-disabled {
          background-color: $black-color;
          color: $white-color;
          .css-nz481w-MuiInputBase-input-MuiInput-input.Mui-disabled {
            -webkit-text-fill-color: $manatee-color;
            color: $manatee-color;
          }
        }
        .css-nz481w-MuiInputBase-input-MuiInput-input.Mui-disabled {
          -webkit-text-fill-color: $manatee-color;
        }
      }
    }
  }
}
