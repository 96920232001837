@import "../../../scss/variables";

.overidesignup {
  .logobutton {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid #caced8;
    background-color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }

  .logo {
    width: 45px;
    height: 35px;
    margin-right: 10px;
  }

  .buttontext {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #043133;
  }

  .already-signup-txt {
    color: #666666;
    font-family: "Montserrat";
    text-align: left;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .login {
    color: #1b436e;
    font-size: 14px;
    font-weight: 700;
    margin-right: 2px;
    text-decoration: none;
    font-family: "Montserrat";
    cursor: pointer;
  }

  .verify-otp {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #caced8;
    border-radius: 8px;
    height: 44px;
    padding-left: 12px;
    ::placeholder {
      color: #caced8;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .show-danger {
    color: #043133;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    cursor: pointer;
  }

  .forgot-password {
    color: #043133;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    cursor: pointer;
  }
  .resendOtp {
    display: flex;
    justify-content: flex-start;
    padding-right: 60px;
  }
  .valid {
    color: #5459ea;
    padding: 7px;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .invalid {
    color: $shadow-grey;
    padding: 7px;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .checkbox {
    font-size: 15px;
    margin-right: 5px;
  }

  .passwordStrenghtCheckStyle {
    box-shadow: rgb(204 204 204) 2px 2px 3px 3px;
    border: 2px rgb(238, 238, 238);
    padding: 2px;
    position: absolute;
    background: #fff;
    margin-left: 280px;
    z-index: 10;
    @media (max-width: 768px) {
      position: relative;
      margin-left: 0;
      width: 100%;
    }
  }
}
