@import "../../scss/variables";

.loader {
  width: 30px; /* Adjust the width of the loader */
  height: 30px; /* Adjust the height of the loader */
  border: 4px solid #f3f3f3; /* Light grey border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animation to make it spin */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
