.sliderOverride {
  .slick-next {
    margin-top: 250px;
    margin-right: 50px;
    display: none !important;
  }
  .cardContainer {
    justify-content: center;
    display: flex;
    max-width: 100%;

    .cardContendBody {
      box-shadow: 0px 0px 7px 1px #00000014;
      margin: 10px 20px 50px;
      padding: 0 20px 20px;
      width: 350px;
      .content {
        color: #331b3b;
        font-size: 16px;
        font-family: "Montserrat";
        padding-top: 30px;
        padding-left: 25px;
        padding-right: 30px;
        padding-bottom: 15px;
        text-align: left;
       
        
        @media (max-width:2500px) {
          height: 300px;
        }
        
        @media (max-width:1180px) {
          height: 330px;
        }
        @media (max-width:1120px) {
          
        }
        @media (max-width:1024px) {
          height: 440px;
        }
        @media (max-width:960px) {
          
        }
        
        @media (max-width:820px) {
          height: 630px;
        }
        @media (max-width:780px) {
          height: 330px;
        }
        @media (max-width: 540px) {
          height: 360px;
        }
      }
      .NameBody {
        color: #000;
        font-size: 16px;
        font-family: "Montserrat";
        font-weight: 600;
        padding-left: 25px;
        text-align: left;
        padding-bottom: 5px;
      }
      .Designation {
        color: #331b3b;
        font-size: 12px;
        font-family: "Montserrat";
        padding-left: 25px;
        text-align: left;
      }
      .imageBody {
        img {
          padding-left: 25px;
          padding-bottom: 15px;
        }
      }
    }
  }
  .ArrowIcon {
    padding-right: 10px;
    .button {
      font-size: 40px;
      cursor: pointer;
    }
  }
}
