.communityCards {
  height: 100vh;
  overflow-y: auto;
  padding-right: 7px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}
.CommunityCardContainer {
  width: 100%;

  .CommunityCard {
    // padding: 25px 25px 35px 25px;
    padding: 20px;
    position: relative;
    // width: 550px;
    // height: 270px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    box-shadow: none;
    text-align: initial;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      padding: 8px;
    }

    .CommunityName {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      margin-left: 25px;
    }
  }

  .CommunityBtnStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .communityicn img {
      border-radius: 4px;
      height: 48px;
      object-fit: cover;
      width: 48px;
    }
    .Communityviewname {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin-right: auto;
      margin-left: 25px;
    }
  }
  .commmtybtn {
    display: flex;
    justify-content: gap;
    align-items: center;
    gap: 30px;
  }

  .CommunityContent {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: initial;
  }
  .CommunityPersonCount {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    //margin-right: 260px;
    // margin-right: auto;
    //margin-left: 25px;
  }
  .CommunityPersonCount1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
    // margin-right: 260px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ButtonStyle {
    background: #278fff;
    border-radius: 8px;
    width: 65px;
    height: 35px;
    text-transform: none;
  }
}
