// .overrideInfoCard {
  .valueContainer {
    display: flow-root;
    // width: 750px;
    background: #F3F3F1;
    border-radius: 48px;
    .ValueIcon{
      display: flex;
      justify-content: center;
    }
    .valueHeading {
      color: #2B3377;
      text-align: center;
      font-size: 20px;
      font-family: "Montserrat";
      font-weight: 700;
    //   line-height: 147.4%;
    //   padding-top: 30px;
    //   padding-bottom: 18px;
    }
    .valueContent {
      color: #585858;
      text-align: center;
      font-size: 16px;
      font-family: "Montserrat";
      line-height: 20px;
    //   padding-right: 15px;
    }
  }
// }
// .overrideInfoCard {
//   @media (max-width: 540px) {
//     .valueContainer {
//       display: flex;
//       flex-direction: column;

//       .valueHeading {
//         font-size: 16px;
//         font-weight: 700;
//         color: #2b3377;
//         padding-top: 12px;
//         padding-left: 8px;
//       }
//       .valueContent {
//         font-size: 12px;
//         font-weight: 400;
//         color: #585858;
//         line-height: 20px;
//         margin-top: 5px;
//       }
//     }
//     .IconHeading {
//       display: flex;

//       .ValueIcon {
//         .graphicon {
//           width: 60px;
//         }
//       }
//     }
//   }
// }
