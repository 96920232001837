@import "../../../scss//variables";

.overideLables {
  .formLabel {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
    color: #083a50;
  }
  .required {
    color: $red-color;
    text-decoration: none;
  }
}

[data-theme="dark"] {
  .overideLables {
    .formLabel {
      color: $white-color;
    }
  }
}
