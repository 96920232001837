.MuiDialog-container {
  .modalPara {
    width: 100%;
    // min-width: 400px;
    text-align: center;
    margin-top: 15px;
    overflow: hidden;
  }

  .MuiDialog-paperWidthSm {
    border-radius: 15px;
    background: #ffffff;
    width: 400px;
    height: fit-content;
    .Dioerrimg {
      overflow: hidden;
    }
    .errimg img {
      width: 33.33px;
      height: 33.33px;
      padding-left: 172px;
      padding-top: 12px;
    }
  }
}
.MuiDialogContent-root {
  overflow: hidden;
}
