@import "../../../scss/variables";

.overideSelectMenu {
  width: 100%;
  .selectBox {
    color: #083a50;
    line-height: 19px;
    letter-spacing: 0px;
    font-family: $font-Montserrat;
    font-weight: 600;
    font-size: $font-size-16;
    width: 100%;
    min-width: 200px;
    // max-width: 500px;
    margin: 0px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    text-align: left;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiSelect-select {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #083a50;
    }
    fieldset {
      border: none;
    }
    svg {
      color: #caced8;
    }
    label {
      // font-family: $font-Montserrat;
      // color: $text-black-color;
      // font-weight: 600;
    }
  }
  .expicon {
    font-size: 30;
    color: #1c1b1f;
  }
  svg:hover {
    cursor: pointer;
  }

  .errorText {
    color: #f21d1d;
    font-family: $font-Montserrat;
    font-size: 12px;
    margin-left: 0;
  }
}

span {
  text-decoration: none;
}
.multiCheck {
  margin-right: 7px;
}

[data-theme="dark"] {
  .overideSelectMenu {
    .selectBox {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
    }
  }
}
