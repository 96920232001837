.SearchedUserCard {
  .cardBody {
    background: #f7f9fc;
    border-radius: 8px;
    // max-width: 300px;
    width: 100%;
    margin-right: 0;
    border: none;
    .profilePic {
      border-radius: 8px;
    }
    .pointer {
      cursor: pointer;
    }
    .pointer:hover {
      cursor: pointer;
    }
    p {
      margin: 0;
      font-family: "Montserrat";
    }
    // .icon{
    //   padding-top: 6px;
    // }
    .name {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #2e3a59;
      margin: 0;
    }
    .designation {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 12px;
      color: #8f9bb3;
      margin: 0;
    }
    .locationIcon {
      // margin-right: 5px;
    }
    .adress {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #2e3a59;
      text-transform: capitalize;
      padding: 0;
      // display: flex;
      // align-items: center;
      text-align: left;
    }
    .country {
      text-transform: capitalize;
      text-align: left;
    }
    .msgBtn {
      border-radius: 8px;
    }
    .msgBtn:hover {
      cursor: pointer;
    }
  }
}
