@import "../../../scss/variables";

.backBtn {
  margin: 30px 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: max-content;
  .backImg {
    line-height: 1;
  }
  .backTitle {
    font-family: $font-Montserrat;
    font-size: $font-size-20;
    color: #666;
    font-weight: 500;
    padding-left: 5px;
  }
}
