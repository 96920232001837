@import "../../scss/variables";
.newCards {
  .blueCardBody {
    font-family: $font-Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-18;
    color: $text-black-color;
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;

    .defaultCard {
      background: $white-color;
      border: 1px solid $border-grey;
      border-radius: 8px;
      padding: 5px 5px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .selectedCard {
      background: $cerulean-color;
      border: 1px solid $border-grey;
      border-radius: 8px;
      padding: 5px 5px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: $white-color;
    }
  }
}
.newCards {
  .staticDataCard {
    margin-bottom: 11px;
    background: transparent;
    color: #333;
    padding: 0px 10px;
    border-radius: 8px;
    display: flex;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    border: 2px solid $border-grey;
    line-height: 0px;
    .staticCardIcon {
      margin-right: 9px;
    }
  }
}

.newCards {
  .borderGreen {
    border: 2px solid #5459EA;
    .staticCardIcon {
      margin-right: 9px;
    }
  }
}
