@import "../../../scss/variables";
.overideLogin {
  height: 99.6vh;
  .leftPic {
    width: 100%;
    height: 99.6vh;
    object-fit: cover;
    object-position: center;
  }
}
.formCon {
  height: 99vh;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: 600px) {
    height: 100vh;
  }
}
.searchcaseid {
  font-family: $font-Montserrat;
  border: 1px solid #caced8;

  background: #ffffff;
  border: 1px solid #caced8;
  border-radius: 8px;
  height: 44px;

  input {
    border: none;
    width: 100%;
    outline: none;
    font-family: $font-Montserrat;
    font-size: $font-size-16;
    // margin-top: 10px;
  }

  input::placeholder {
    font-weight: 400;
    color: $manatee-color;
    font-size: $font-size-16;
  }
  input:active,
  input:focus {
    color: #043133;
    font-family: $font-Montserrat;
  }

  .inputRoot {
    // border-radius: 16px;
    // margin-bottom: 0px;
    // padding: 7.5px 15px;
    &.Mui-disabled {
      background-color: white;
    }
  }
  // .MuiFormHelperText-root {
  //   position: absolute;
  //   bottom: -25px;
  //   left: 0;
  // }
}

// .overideLogin{
.errorMsg {
  margin: 0;
  font-family: $font-Montserrat;
  color: $red-color;
  font-size: 12px;
  text-align: left;
}
// }
.valid {
  color: #5459ea;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.invalid {
  color: $shadow-grey;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}

.show-error {
  gap: 3px;
  width: 100%;
  color: $red-color;
  display: flex;
  margin-top: 6px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  // &:before {
  //   width: 13px;
  //   content: "";
  //   height: 13px;
  //   background: url("../assets/form-error.svg") no-repeat;
  //   background-size: contain;
  // }
}

.already-signup-txt {
  color: #666666;
  font-family: "Montserrat";
  text-align: left;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  .login {
    color: #1b436e;
    font-size: 14px;
    font-weight: 700;
    margin-right: 2px;
    text-decoration: none;
    font-family: "Montserrat";
    cursor: pointer;
  }
}
