// #TempHolder {
//   text-align: left;
//   padding: 20px 25px 0px 25px;
// }
.tempHeader1 {
  text-align: left;
  padding: 20px 25px 0px 25px;
}
.tempHeader2 {
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.wrapperTemp2 {
  padding: 10px 20px 20px 20px;
}
.name {
  font-size: 24px;
  font-weight: 500;
}
.name,
.professional {
  margin: 10px 0px;
}
.contactBox {
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.sortDesc {
  margin: 20px 0px;
}
.expBorder {
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid;
  position: relative;
}
.iconss {
  position: absolute;
  bottom: -12px;
  left: -9px;
}
.expsss {
  margin-left: 27px;
}
.expDateBody {
  display: flex;
  margin: 20px 0px;
}

.expoDate {
  margin-right: 32px;
}
.isonSizes {
  width: 20px !important;
  height: 20px !important;
}
.tableP {
  p {
    margin-bottom: 10px;
  }
}

.resumeTemplste4 {
  p {
    margin-bottom: 10px;
  }
}
