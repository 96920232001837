.videoWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: move;
  .closeVideo {
    position: absolute;
    right: 0;
    top: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 9;
    font-size: 14px;
    font-weight: 400;
  }
}
.homepage-override {
  position: relative;
  .navbarstyle {
    background-color: #ffffff;
  }
  .banner-style {
    width: 100%;
    height: 804px;
  }
  .banner {
    background-image: url(../../assets//Landing/banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    padding: 10%;
    position: relative;
  }
  .heading {
    color: #fff;
    font-size: 64px;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 126.9%;
  }
  .subHeading {
    color: #fff;
    font-size: 24px;
    font-family: "Montserrat";
    line-height: 126.9%;
    margin-top: 30px;
  }
  .btnBlock {
    margin-top: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    .JoinbtnStyle {
      background-color: #ffff;
      text-transform: none;
      color: #5958f1;
      font-size: 26px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 126.9%;
      box-shadow: none;
      padding: 16px;
      border-radius: 10px;
    }
    .WhyBtnStyle {
      text-transform: none;
      color: #fff;
      font-size: 26px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 126.9%;
      text-decoration: underline;
    }
  }

  .subHeading2 {
    color: #515151;
    font-size: 35px;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 44px;

    padding-top: 80px;
    padding-bottom: 75px;
    padding: 80px;
  }
  .subHeading3 {
    color: #565656;
    text-align: center;
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 147.4%;
    letter-spacing: 0.96px;
  }
  .subHeading4 {
    color: #5459ea;
    text-align: center;
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 147.4%;
    letter-spacing: 0.96px;
  }
  .companyStyle {
    padding-top: 30px;
    .subHeading5 {
      color: #565656;
      text-align: center;
      font-size: 40px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 147.4%;
      letter-spacing: 0.96px;
    }
    .subHeading6 {
      color: #3adad9;
      text-align: center;
      font-size: 40px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 147.4%;
    }
  }

  .companyConatainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 70px;
    gap: 80px;
    @media (max-width:1150px) {
      
      justify-content: center;
      flex-direction: column;
      padding: 0 7%;
      gap: 40px;
    }
    .companyImage {
      @media (max-width: 2560px) {
        width: 412px;
      }
      @media (max-width: 2160px) {
        width: 512px;
        // height: 504px;
      }
      @media (max-width: 2048px) {
        width: 452px;
        // height: 444px;
      }
      @media (max-width: 1920px) {
        width: 452px;
        // height: 444px;
        display: flex;
        justify-content: center;
      }
      @media (max-width: 1440px) {
        width: 612px;
        // height: 504px;
      }
      @media (max-width: 1280px) {
        width: 512px;
        // height: 404px;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
      // @media (max-width: 992px) {
      //   width: 400px;
      // }
      // @media (max-width: 540px) {
      //   width: 300px;
      // }
      .Comapnyicon {
        width: 100%;
        margin-top: 70px;
        @media (max-width: 2048px) {
          margin-top: 0px;
        }
        @media (max-width: 1280px) {
          margin-top: 0px;
        }
      }
    }
    .companyContent {
      padding-right: 40px;
      @media (max-width:600px) {
        padding: 0;
      }
      .companyHeading1 {
        color: #4a4a4a;
        font-size: 28px;
        font-family: "Montserrat";
        font-weight: 700;
        line-height: 147.4%;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (max-width: 992px) {
          line-height: 30px;
        }
      }
      .companySubheading1 {
        color: #575757;
        font-size: 16px;
        font-family: "Montserrat";
        line-height: 24px;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (max-width: 992px) {
          line-height: 20px;
        }
      }

      .companyHeading2 {
        color: #4a4a4a;
        font-size: 28px;
        font-family: "Montserrat";
        font-weight: 400;
        line-height: 147.4%;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 35px;

        @media (max-width: 992px) {
          line-height: 30px;
        }
      }
      .companySubheading2 {
        color: #575757;
        font-size: 16px;
        font-family: "Montserrat";
        line-height: 38px;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (max-width: 992px) {
          line-height: 20px;
        }
      }

      .companyHeading3 {
        color: #4a4a4a;
        font-size: 28px;
        font-family: "Montserrat";
        font-weight: 400;
        line-height: 147.4%;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 35px;
      }
      .companySubheading3 {
        color: #575757;
        font-size: 16px;
        font-family: "Montserrat";
        line-height: 38px;
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }
  }
  .IconHeading {
    // margin-top: 60px;
    .subHeading7 {
      color: #5459ea;
      text-align: center;
      font-size: 38px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 147.4%;
    }
    .subHeading8 {
      color: #565656;
      text-align: center;
      font-size: 38px;
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 147.4%;
    }
  }
  .Icon-middle ul {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 35px;
    justify-content: center;
  }

  .social_icon {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 45px;
    .socialIcons {
      padding: 20px;
      .social-logo {
        width: 120px;
        cursor: pointer;
      }
    }
    li {
      width: 70px;
      height: 70px;
      text-decoration: none;
      background: #f4f6f8;
      padding: 16px;
      border-radius: 5px;
      margin-top: 50px;
      margin-bottom: 120px;
      cursor: pointer;
    }
    // img {
    //   width: 100%;
    //   height: 100%;
    // }
  }
  .aboutUserStyle {
    color: #5459ea;
    text-align: center;
    font-size: 38px;
    font-family: "Montserrat";
    font-weight: 400;
  }
  .carouserBody {
    margin-top: 85px;
    margin-bottom: 70px;
  }
}
.setupSection {
  background-color: #fafaff;
  .imageSection {
    img {
      width: 100%;
      // margin-left: 90px;
    }
  }
  .minutDivStyle {
    text-align: left;
    margin-left: 70px;
    @media (max-width:600px) {
      margin-left: 3%;
    }
    .minutHeading {
      color: #4a4a4a;
      font-size: 38px;
      font-family: "Montserrat";
      font-weight: 400;
      &:after {
        content: "";
        display: block;
        position: relative;
        width: 8%;
        border: 2px solid #fed369;
        border-radius: 2px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    .minutSubHeading {
      color: #575757;
      font-size: 24px;
      font-family: "Montserrat";
      font-weight: 400;
    }
  }
}
.homepage-override {
  // @media (max-width: 2580px) {
  //   padding: 0 250px;
  // }
  // @media (max-width: 2040px) {
  //   padding: 0 200px;
  // }
  // @media (max-width: 1820px) {
  //   padding: 0 250px;
  // }
  // @media (max-width: 1560px) {
  //   padding: 0 200px;
  // }
  @media (max-width: 1440px) {
    padding: 0;
  }
  @media (max-width: 1280px) {
    padding: 0;
    .footer-top {
      .connectDetail {
        .aboutUsStyle {
          font-size: 24px;
          font-weight: 500;
        }
        .tcStyle {
          font-size: 24px;
          font-weight: 500;
        }

        .privacyStyle {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    
    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .footer-logo {
        // width: 183px;
        height: 76px;
        // padding: 0 39%;
        margin-bottom: 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .footer-top {
        .container {
          .connectDetail {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding: 0;
          }
          .rightstyleLi{
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .rightstyle {
            font-size: 12px;
            font-weight: 400;
            // margin-left: -10%;
            margin-top: 152px;
            position: absolute;
            padding-bottom: 78px;
          }
          .aboutUsStyle {
            font-size: 12px;
            font-weight: 700;
          }
          .tcStyle {
            font-size: 12px;
            font-weight: 700;
          }
        }
        .privacyStyle {
          font-size: 12px;
          font-weight: 700;
        }
      }
      .footer-bottom {
        display: flex;
        justify-content: center;
        .container {
          .float-left {
            .footer_social {
              padding-left: 0px;
              padding-top: 22px;
              .SoIcon {
                .fb-logo .linkedin-logo {
                  width: 30.29px;
                  height: 30.29px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .social_icon {
      .socialIcons {
        padding: 16px;
        .social-logo {
          width: 80px;
        }
        .twitterIconContainer {
          background: #f4f6f8;
          // padding: 20px;
          width: 100%;
          border-radius: 8px;
          .twitter-logo {
            height: 80%;
            width: 80%;
          }
        }
      }
    }
    .setupSection {
      width: 100%;
      display: flex;
      flex-direction: row;
      .setupSection1 {
      }
    }
    
  }
  @media (max-width: 540px) {
    .override-Navbar {
      margin: 3%;
      .logo {
        width: 115px;
        height: 46px;
      }
      .buttonBody {
        .btnLogin {
          .loginbtn {
            font-size: 12px;
            font-weight: 600;
            margin-top: 2px;
          }
        }
        .btnSignup {
          .signupbtn {
            width: 92px;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 600;
            height: 34px;
          }
        }
      }
    }
    .bannerContainer {
      .banner {
        background-image: url(../../assets/Landing/mobileBanner.svg);
        height: 515px;
        padding: 20px;
      }
    }
    .heading {
      font-size: 36px;
      font-weight: 700;
      margin-top: 30px;
    }
    .subHeading {
      font-weight: 400;
      font-size: 20px;
      margin-top: 50px;
    }
    .btnBlock {
      .JoinbtnStyle {
        width: 134.01px;
        height: 40.79px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
      }
      .WhyBtnStyle {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .subHeading2 {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      line-height: 30px;
      padding-left: 25px;
      margin-right: 12px;
    }
    .forProfessionals {
      text-align: left;
      margin-left: 25px;
      .subHeading3 {
        font-size: 24px;
        font-weight: 600;
      }
      .subHeading4 {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .forProfessionals {
      margin-left: 30px;
    }
    .companyStyle {
      padding-left: 25px;
      text-align: left;
      .subHeading5 {
        font-size: 24px;
        font-weight: 700;
      }
      .subHeading6 {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .companyConatainer {
      display: flex;
      flex-direction: column;
      
      .companyImage {
        .Comapnyicon {
          width: 100%;
        }
      }
      .companyContent {
        .companyHeading1 {
          font-size: 20px;
          font-weight: 700;
          margin-top: 20px;

          @media (max-width: 992px) {
            line-height: 20px;
          }
        }
        .companySubheading1 {
          font-size: 12px;
          font-weight: 400px;
          line-height: 14px;
          margin-top: 15px;
        }
      }
    }

    .IconHeading {
      .subHeading7 {
        font-size: 20px;
        font-weight: 700;
      }
      .subHeading8 {
        font-size: 20px;
        font-weight: 600;
      }
      text-align: left;
    }
    .Icon-middle {
      display: flex;
      flex-direction: column;
    }
    .social_icon {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .socialIcons {
        // padding: 10px;
        width: 30%;
      }
    }
    .social_icon li {
      width: 120px;
    }
    .aboutUserStyle {
      display: none;
    }
    .carouserBody {
      margin-top: 0px;
    }
    .setupSection {
      .imageSection img {
        margin-left: 0;
      }
    }
    .setupSection {
      .minutHeading {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .minutDivStyle {
      .minutSubHeading {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
