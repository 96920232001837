.overrideBookSessionForm {
 
  .slot {
    background: #eeeeee;
    border-radius: 8px;
    padding: 5px 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat";
  }
  .errMsg{
    color: red;
    font-size: 12px;
  }
  .btn {
    .overideBtn {
      width: 100%;
      .containedButton {
        width: 100%;
        max-width: 320px;
        color: #ffff;
        background: #278fff;
        border-radius: 8px;
      }
    }
  }
}
