.override-Navbar {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;

  .logo {
    width: 267px;
    height: 114px;
    @media (max-width: 540px) {
      
    width: 115px;
    height: 45px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo:hover {
    cursor: pointer;
  }
  .buttonBody {
    display: flex;
    .btnLogin {
      .loginbtn {
        text-transform: none;
        color: #757575;
        font-size: 22px;
        font-family: "Montserrat";
        font-weight: 400;
        margin-right: 15px;
      }
      .loginbtn:hover {
        text-decoration: none;
        background-color: #ffff;
      }
    }
    .btnSignup {
      .signupbtn {
        text-transform: none;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background: #778dff;
        color: #f7f9fc;
        font-size: 22px;
        font-family: "Montserrat";
        font-weight: 400;
      }
      .signupbtn:hover {
        background-color: #778dff;
        text-decoration: none;
      }
    }
  }
}
