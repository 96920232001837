.ProfileCardContainerr {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .ProfileCard {
    box-shadow: none;
    height: 303px;
    width: 100%;
    max-width: 100%;
    background: #ffffff;
    margin-top: 10px;
    @media only screen and (max-width: 907px) {
      max-width: 100%;
    }
  }

  .ProfileCardImage {
    display: flex;
    // gap: 10px;
    width: 129px;
    height: 129px;
    margin: auto;
    // background: #fa5c5c;
    border-radius: 8px;
    padding-left: 5px;
    cursor: pointer;
    img {
      border-radius: 16px;
    }
  }

  .ProfileImage {
    margin-top: 15px;
    padding: 0px 0px 0px 0px;
    width: 119px;
    height: 124px;
  }
  .columns {
    margin-top: 10px;
    .ViewName {
      width: auto;
      height: 20px;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 0;
      cursor: pointer;
    }
    .ViewRole {
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #666666;
      width: auto;
    }
    .ViewContact {
      width: auto;
      height: 19px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #666666;
    }
    .column4 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 5px;

      .smtext {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 550;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        height: 19px;
        color: #000000;
      }
      .instaLogo {
        height: 20px;
        width: 20px;
        transition: all 0.3s;
      }

      .facebookLogo {
        height: 20px;
        width: 20px;
      }
      .twitterLogo {
        height: 20px;
        width: 28px;
      }
      .pintrestLogo {
        height: 28px;
        width: 28px;
      }
    }
  }
}
