@import "../../../scss/variables";

.overideBtn {
  .textButton {
    font-family: $font-Montserrat;
    color: $Eerie-Black;
    font-weight: 500;
    font-size: $font-size-14;
    text-transform: capitalize;
    .icon {
      margin-right: 10px;
    }
  }
  .containedButton {
    padding: 12px 20px;
    height: 41px;
    background-color: #278fff;
    border-radius: 8px;
    text-transform: capitalize;
    max-width: 300px;
    // font-family:$font-Montserrat;
    font-size: $font-size-14;
    font-weight: 600;
    line-height: $font-size-22;
    box-shadow: none;
    font-family: $font-Montserrat;
  }
  .containedLeaveButton {
    padding: 12px 20px;
    height: 41px;
    background-color: #ff333f;
    border-radius: 8px;
    text-transform: capitalize;
    max-width: 300px;
    // font-family:$font-Montserrat;
    font-size: $font-size-14;
    font-weight: 600;
    line-height: $font-size-22;
    box-shadow: none;
    font-family: $font-Montserrat;
  }
  .outlinedButton {
    //color: $cerulean-color;
    color: #000000;
    // width: 200px;
    text-transform: capitalize;

    box-shadow: none;
    background: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    height: 44px;
    max-width: 150px;
  }
  .containedPostButton {
    padding: 12px 20px;
    //height: 41px;
    background-color: #778dff;
    border-radius: 8px;
    text-transform: capitalize;
    max-width: 130px;
    // font-family:$font-Montserrat;
    font-size: $font-size-14;
    font-weight: 600;
    line-height: $font-size-22;
    box-shadow: none;
    width: 100%;
    float: right;

    margin-bottom: 1rem;
    font-family: $font-Montserrat;
    // width: 124px;
    //margin-left: 15rem;
  }
  .containedCommentButton {
    padding: 12px 20px;
    //height: 41px;
    background-color: #778dff;
    border-radius: 8px;
    text-transform: capitalize;
    max-width: 154px;

    font-size: $font-size-14;
    font-weight: 600;
    line-height: $font-size-22;
    box-shadow: none;
    width: 100%;
    float: right;
    margin-bottom: 1rem;
    font-family: $font-Montserrat;
    //width: 150px;
    //margin-left: 29rem;
  }
}
