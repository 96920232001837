.overideMentorshipRequestCard {
  .overideBtn .containedButton {
    width: fit-content;
  }
  p {
    font-family: "Montserrat";
    font-size: 16px;
  }
  .font400 {
    font-weight: 400;
  }
  .font700 {
    font-weight: 700;
  }
  border-bottom: 1px solid #999999;
  padding-bottom: 10px;
  .btn {
    .overideBtn {
      width: 100%;
      .containedButton {
        width: 100%;
        max-width: 320px;
        color: #ffff;
        background: #278fff;
        border-radius: 8px;
      }
    }
  }
}
