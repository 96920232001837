@import "../../../scss/variables";

.overidesignup {
  height: 99.6vh;
  .leftPic {
    width: 100%;
    height: 99.6vh;
    object-fit: cover;
    object-position: center;
  }
  // .light-logo {
  //   width: 267px;
  //   height: 114px;
  //   cursor: "pointer";
  //   margin-top: 10%;
  //   @media (max-width: 540px) {
  //     width: 207px;
  //     height: 94px;
  //   }
  // }
  .logobutton {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid #caced8;
    background-color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }

  .logo {
    width: 45px;
    height: 35px;
    margin-right: 10px;
  }

  .buttontext {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #043133;
  }

  .already-signup-txt {
    color: #666666;
    font-family: "Montserrat";
    text-align: left;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .login {
    color: #1b436e;
    font-size: 14px;
    font-weight: 700;
    margin-right: 2px;
    text-decoration: none;
    font-family: "Montserrat";
    cursor: pointer;
  }
  .errorMsg {
    color: red;
    font-size: 12px;
    // padding: 5px;
    margin: 2px;
    font-weight: 400;
    font-family: "Montserrat";
  }
}
