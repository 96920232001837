@import "../../../scss/variables";

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.accordion_body {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: none;
  @media (max-width:600px) {
    max-width: 300px;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    color: $white-color;
    // @media (max-width:600px) {
    //   max-width: 70%;
    // }
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
      color: $white-color;
      margin: 0;
     
      .accordian_header_content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        p {
          color: #083a50;
          font-weight: 700;
          font-size: 16px;
          font-family: $font-Montserrat;
        }
        .accordian_header_content_child {
          display: flex;
          flex-wrap: wrap;
          button {
            outline: none;
            background: none;
            border: none;
            margin-top: 5px;
            cursor: pointer;
            font-family: $font-Montserrat;
          }
          button:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: $white-color;
    }
  }

  .Mui-expanded {
    border-radius: 15px 15px 0 0;
  }
  .MuiCollapse-root.MuiCollapse-vertical {
    background-color: #fff;
    .MuiCollapse-wrapper.MuiCollapse-vertical {
      width: 100%;
      .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        background-color: $white-color;
        box-shadow: 0px 0px 1px 1px rgba(112, 112, 112, 0.1);
        padding: 20px;
        @media (max-width:600px) {
          max-width: 400px;
        }
        .MuiAccordionDetails-root {
          padding: 0px;
          .MuiFormLabel-root.MuiFormLabel-colorPrimary {
            color: $shadow-grey;
          }
        }
      }
    }
  }
  .form_label_error {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.supportAccordion {
  margin-bottom: 10px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    p {
      font-family: $font-Montserrat;
      font-weight: 700;
      font-size: $font-size-22;
      color: $text-black-color;
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: #1c1b1f;
    }
  }
}
.accordion_body {
  .parentAccordion {
    background-color: #ebebeb;

    border: 1px solid #caced8;
    border-radius: 15px;
  }
  .childAccordion {
    .MuiAccordionSummary-gutters {
      background-color: $cerulean-color;
    }
  }
}
.quesLable {
  display: flex;
  .spaceLable {
    margin-right: 5px;
  }
}
