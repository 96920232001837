.greenBtn {
  padding: 10px;
  background: #17b712;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 8px;
  color: #fff;
}
.redBtn {
  padding: 10px;
  background: #c40223;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 8px;
  color: #fff;
}

.overideBtn .containedButton {
  width: 190px;
  height: 41px;
  background-color: #778dff;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
.joinbtn .overideBtn .containedButton {
  width: 71px;
  height: 41px;
  background-color: #778dff;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
