@import "../../../scss/variables";

.overideDatePicker {
  .formControlRoot {
    background: $white-color;
    border-radius: 8px;

    .MuiOutlinedInput-root{
      
    // border: 1px solid #CACED8;
    font-family: $font-Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-16;
    color:  #083a50;

    }

    // border: 1px solid #CACED8;
    font-family: $font-Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-16;
    color:  #083a50;
    margin-bottom: 0px;

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid #caced8;
    }
    svg {
      color: #778dff;
    }
    svg:hover {
      color: #778dff;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: 40px;
        padding: 1.5px 14px;

        fieldset:active {
          border: none;
        }
      }
    }

    .Mui-disabled {
      background-color: $fog-grey;
      border-radius: 8px;

      // border: 1px solid #CACED8;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      padding: 3px 15px;
      height: 48px;

      border: 1px solid #caced8;
    }
  }
  .inputRoot {
    border-radius: 16px;
    padding: 10px 15px;
  }

  .errorText {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f21d1d;
  }
}
[data-testid="CalendarIcon"] {
  color: $text-black-color;
  // background-image: url(../../../assets/calendar_month.svg);
  background-repeat: no-repeat;
  background-position: center;
  path {
    // display: none;
  }
}

[data-theme="dark"] {
  .overideDatePicker {
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      .MuiInputBase-root {
        .MuiInputBase-input {
          color: $white-color;
          &:focus {
            border: none;
          }
        }
      }
      &.Mui-disabled {
        background-color: $black-color;
        color: $white-color;
      }
      .inputRoot {
        color: $white-color;
      }
    }
  }
}
