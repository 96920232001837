.NewsCardContainer {
  .Card {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: none;
    .cardImage {
      width: 100%;
      height: 100%;
      background: white;
      margin: 47px;
      margin-left: 13px;
      .css-o69gx8-MuiCardMedia-root {
        height: 60px;
        width: 120px;
        border-radius: 10px;
      }
    }
    .columns {
      margin-left: -56px;
      margin-top: 30px;
    }
    .listViewName {
      font-weight: 600;
      font-size: 13px;
      color: #333333;
      font-family: "Montserrat";
      width: 492.55px;
      height: 42px;
    }
    .listViewRole {
      font-weight: 500;
      font-size: 14px;
      color: #2e3a59;
    }
    .right {
      display: flex;
      flex-direction: row;
    }
  }
}
.newsCardContainer {
  display: flex;
  margin-bottom: 36px;
}

.imgBox {
  height: 101px;
  //width: 198px;
  border-radius: 10px;
  img {
    height: 95px;
    width: auto;
    border-radius: 10px;
  }
}

.contentBox {
  margin-left: 20px;
  position: relative;
}
.simpleText {
  font-size: 20px;
  color: #333333;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  // margin-left: 36px;
}

.heding {
  margin-top: 10px;
}
.seperators {
  border: 0.8px solid #e0e0e0;
  position: absolute;
  /* height: 4px; */
  width: 100%;
  color: #000;
  bottom: 5px;
}
.dropboxicn {
  margin-top: 5px;
}
