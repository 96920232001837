.Aboutus-override {
  .HeadingContainer {
    .heading {
      color: #515151;
      text-align: center;
      font-size: 48px;
      font-family: "Montserrat";
      font-weight: 700;
    }
    .Subheading {
      color: #515151;
      text-align: center;
      font-size: 24px;
      font-family: "Montserrat";
    }
    .heading,
    .Subheading {
      @media (max-width: 540px) {
        text-align: left;
      }
    }
    .heading {
      @media (max-width: 540px) {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .Subheading {
      @media (max-width: 540px) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .visionMainConatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    .visionImagem {
      width: 750px;
      height: 540px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .visionImagem {
      @media (max-width: 960px) {
        display: none;
      }
    }
    .VisionSubContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      width: 750px;
      height: auto;
      padding-left: 25px;
      @media (max-width: 1000px) {
        padding-left: 0;
      }
      .visionImagemMob {
        width: 321px;
        height: auto;
        @media (max-width:960px) {
          width: 100%;
        }
        img {
          height: auto;
          width: 100%;
        }
      }
      @media (max-width: 540px) {
        height: min-content;
      }
      .VisionmainHeading {
        color: #5459ea;
        text-align: center;
        font-size: 38px;
        font-family: "Montserrat";
        font-weight: 700;
        width: 100%;
        text-align: left;
      }
      .visionHeading {
        color: #4a4a4a;
        font-size: 28px;
        font-family: "Montserrat";
        font-weight: 600;
        width: 100%;
      }
      .visionSubHeading {
        color: #575757;
        font-size: 18px;
        font-family: "Montserrat";
        width: 100%;
      }

      .VisionmainHeading,
      .visionHeading {
        @media (max-width: 540px) {
          font-size: 20px;
          font-weight: 700;
        }
      }
      .visionSubHeading {
        @media (max-width: 540px) {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .VisionmainHeading,
      .visionHeading,
      .visionSubHeading {
        @media (max-width: 540px) {
          text-align: left;
        }
      }
    }
  }
  .aboutmatchupIt {
    background-color: #fafaff;
    background-color: #fafaff;
    max-width: 96%;
    margin-left: 25px;
    margin-top: 30px;
    @media (max-width: 1040px) {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 5%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 600px) {
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 10%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .aboutmatchupItText {
      color: #278fff;
      text-align: center;
      font-size: 42px;
      font-family: "Montserrat";
      font-weight: 600;
      text-align: center;
      @media (max-width: 540px) {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .howtartedConatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .ownerImage {
      width: 400px;
      height: 400px;
      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 960px) {
        display: none;
      }
    }
    .howstartedContainer {
      width: 800px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding-left: 10px;
      @media (max-width: 1000px) {
        padding-left: 0;
      }
      .ownerImageMob{
        @media (max-width:1000px) {
          width: 100%;
          img{width: 100%;}
        }
      }
      .howstartedMainHeading {
        color: #5459ea;
        text-align: center;
        font-size: 48px;
        font-family: "Montserrat";
        font-weight: 600;

        @media (max-width: 540px) {
          text-align: left;
          font-size: 24px;
          font-weight: 700;
        }
      }

      .howstartedSubHeading {
        color: #575757;
        font-size: 18px;
        font-family: "Montserrat";
        // line-height: 38px;
        @media (max-width: 540px) {
          text-align: left;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .howstartedSubHeading b {
        color: #5459ea;
        font-size: 18px;
        font-family: "Montserrat";
        // line-height: 38px;
        @media (max-width: 540px) {
          text-align: left;
          font-size: 14px;
        }
      }
      .howstartedSubHeading p {
        margin-top: 30px;
      }
    }
  }
}

.Aboutus-override {
  // @media (max-width: 540px) {
  //   .footer {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     // margin-top: 800px;
  //     .footer-logo {
  //       height: 76px;
  //       padding: 0 25%;
  //     }
  //     .footer-top {
  //       margin-top: 20px;
  //       .container {
  //         .connectDetail {
  //           display: flex;
  //           flex-direction: column;
  //         }
  //         .rightstyle {
  //           font-size: 12px;
  //           font-weight: 400;
  //           margin-left: -82px;
  //           margin-top: 152px;
  //           position: absolute;
  //           padding-bottom: 78px;
  //         }
  //         .aboutUsStyle {
  //           font-size: 12px;
  //           font-weight: 700;
  //         }
  //         .tcStyle {
  //           font-size: 12px;
  //           font-weight: 700;
  //         }
  //       }
  //       .privacyStyle {
  //         font-size: 12px;
  //         font-weight: 700;
  //       }
  //     }
  //     .footer-bottom {
  //       padding-left: 113px;
  //       .container {
  //         .float-left {
  //           .footer_social {
  //             padding-left: 0px;
  //             padding-top: 22px;
  //             .SoIcon {
  //               .fb-logo .linkedin-logo {
  //                 width: 30.29px;
  //                 height: 30.29px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.Aboutus-override {
  @media (max-width: 1024px) {
    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .footer-logo {
        // width: 183px;
        height: 76px;
        // padding: 0 39%;
        margin-bottom: 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .footer-top {
        .container {
          .connectDetail {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding: 0;
          }
          .rightstyleLi {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .rightstyle {
            font-size: 12px;
            font-weight: 400;
            // margin-left: -10%;
            margin-top: 152px;
            position: absolute;
            padding-bottom: 78px;
          }
          .aboutUsStyle {
            font-size: 12px;
            font-weight: 700;
          }
          .tcStyle {
            font-size: 12px;
            font-weight: 700;
          }
        }
        .privacyStyle {
          font-size: 12px;
          font-weight: 700;
        }
      }
      .footer-bottom {
        display: flex;
        justify-content: center;
        .container {
          .float-left {
            .footer_social {
              padding-left: 0px;
              padding-top: 22px;
              .SoIcon {
                .fb-logo .linkedin-logo {
                  width: 30.29px;
                  height: 30.29px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .social_icon {
      .socialIcons {
        padding: 16px;
        .social-logo {
          width: 80px;
        }
        .twitterIconContainer {
          background: #f4f6f8;
          // padding: 20px;
          width: 100%;
          border-radius: 8px;
          .twitter-logo {
            height: 80%;
            width: 80%;
          }
        }
      }
    }
    .setupSection {
      width: 100%;
      display: flex;
      flex-direction: row;
      .setupSection1 {
      }
    }
  }
  @media (max-width: 540px) {
    .override-Navbar {
      margin: 3%;
      .logo {
        width: 115px;
        height: 46px;
      }
      .buttonBody {
        .btnLogin {
          .loginbtn {
            font-size: 12px;
            font-weight: 600;
            margin-top: 2px;
          }
        }
        .btnSignup {
          .signupbtn {
            width: 92px;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 600;
            height: 34px;
          }
        }
      }
    }
    .bannerContainer {
      .banner {
        background-image: url(../../assets/Landing/mobileBanner.svg);
        height: 515px;
        padding: 20px;
      }
    }
    .heading {
      font-size: 36px;
      font-weight: 700;
      margin-top: 30px;
    }
    .subHeading {
      font-weight: 400;
      font-size: 20px;
      margin-top: 50px;
    }
    .btnBlock {
      .JoinbtnStyle {
        width: 134.01px;
        height: 40.79px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
      }
      .WhyBtnStyle {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .subHeading2 {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      line-height: 30px;
      padding-left: 25px;
      margin-right: 12px;
    }
    .forProfessionals {
      text-align: left;
      margin-left: 25px;
      .subHeading3 {
        font-size: 24px;
        font-weight: 600;
      }
      .subHeading4 {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .forProfessionals {
      margin-left: 30px;
    }
    .companyStyle {
      padding-left: 25px;
      text-align: left;
      .subHeading5 {
        font-size: 24px;
        font-weight: 700;
      }
      .subHeading6 {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .companyConatainer {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .companyImage {
        .Comapnyicon {
          width: 100%;
        }
      }
      .companyContent {
        .companyHeading1 {
          font-size: 20px;
          font-weight: 700;
          margin-top: 20px;

          @media (max-width: 992px) {
            line-height: 20px;
          }
        }
        .companySubheading1 {
          font-size: 12px;
          font-weight: 400px;
          line-height: 14px;
          margin-top: 15px;
        }
      }
    }

    .IconHeading {
      .subHeading7 {
        font-size: 20px;
        font-weight: 700;
      }
      .subHeading8 {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .Icon-middle {
      display: flex;
      flex-direction: column;
    }
    .social_icon {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .socialIcons {
        // padding: 10px;
        width: 30%;
      }
    }
    .social_icon li {
      width: 120px;
    }
    .social_icon img {
      // width: 70px;
      // margin-right: 50px;
      // margin-left: -30px;
      // padding: 20px;
    }
    .aboutUserStyle {
      display: none;
    }
    .carouserBody {
      margin-top: 0px;
    }
    .setupSection {
      .imageSection img {
        margin-left: 0;
      }
    }
    .setupSection {
      .minutHeading {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .minutDivStyle {
      .minutSubHeading {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
