@import "../../../../src/scss/variables";

.overideProfile {
  .topHeaderProfile {
    .profileImg {
      max-width: 180px;
      width: 100%;
      height: 180px;
      position: relative;
      .borderProfileImg {
        border-radius: 100%;
        border: 8px solid #778dff;
        width: 100%;
        height: 100%;
      }
      .editProfileicon {
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background-color: #778dff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -10px;
        right: -2px;
      }
      .editProfileicon:hover {
        cursor: pointer;
      }
    }
  }
  .ProfileConatainer {
    // display: flex;
    width: 100%;
    // flex-direction: row;
    margin: 0;
    padding: 0;
    margin-top: 50px;
    .leftSidePanel {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      width: 25%;
      // overflow-y: scroll;
      @media (max-width:960px) {
        display: none;
      }
      border-right: 4px solid rgba(232, 234, 238, 0.4);
      .tabName {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 16px;
        color: #caced8;
        margin: 20px 0;
        max-width: 200px;
        cursor: pointer;
        &.active {
          background: rgba(119, 141, 255, 0.15);
          border-radius: 8px;
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 16px;
          color: #083a50;
          padding: 10px;
        }
      }
    }
    .rightPanel {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 75%;
      margin: 0;
      padding: 0;
      
      @media (max-width:960px) {
        width: 100%;
      }
      // overflow-y: scroll;
      text-align: center;
      .textButton {
        color: #778dff;
        font-weight: 600;
      }
      .label {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;

        color: #083a50;
      }
      .fName {
        display: flex;
        flex-direction: row;
        width: 100%;
        // justify-content: space-between;
        gap: 10px;
      }
      .selectLabel {
        display: flex;
        justify-content: flex-Start;
        align-items: flex-Start;
      }
      .overideSelectMenu {
        .selectBox {
          min-width: 45px;
        }
      }
      .overideLables .formLabel {
        text-align: left;
        margin-bottom: 5px;
      }
      .basicDetailsForm {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 0px 0 10px 50px;
        .saveBtn{
          .overideBtn .containedButton {
            width: fit-content;
          }
          @media (max-width:600px) {
            width: 100%;
            .overideBtn .containedButton {
              width: 100%;
            }}
        }
        .cancelBtn{
          
          @media (max-width:600px) {
            width: 100%;
            .overideBtn .containedButton {
              width: 100%;
              margin-left: 0;
              margin: 0;
            }}
        }
        @media (max-width:960px) {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  .editProfileBtn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 15px;
    margin-right: 2px;
    .saveBtn .overideBtn .containedButton {
      width: 190px;
      height: 41px;
      background-color: #778dff;
      border-radius: 8px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
    .cancelBtn .overideBtn .containedButton {
      width: 89px;
      height: 41px;
      background-color: #f0f0f0;
      margin-left: 10px;
      border-radius: 8px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 14px;
      color: #2e3a59;
    }
  }
}
