@import "../../../scss//variables";

.searchid {
  position: relative;
  // max-width: 100%;
  width: 100%;
  // flex: 50%;
  display: flex;
  font-family: $font-Montserrat;

  img{
    margin-top: 5px;
  }

  label {
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 5px 0px;
    span {
      width: 50px;
      svg {
        font-size: $font-size-26;
        color: #999999;
        margin-top: 5px;
      }
    }
    div {
      input {
        font-family: "Montserrat";
        width: 100%;
        max-height: 50px;
        padding: 0px;
        font-size: $font-size-16;
        outline: none;
        border: none;
        height: 45px;
      }

      input:active,
      input:focus {
        color: #828996;
      }
    }
  }
  .serchInputField {
    width: 85%;
    height: 100%;
    border: none;
    outline: none;
    min-height: 35px;
  }
  input::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #999999;
  }
}
