@import "../../../scss/variables";
.CommunityName0 {
  display: flex;
  align-items: center;
  .imageStyle {
    width: 13%;
    img {
      width: 56px;
      border-radius: 10px;
      height: 56px;
    }
  }
  .NameDateStyle {
    display: flex;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 5px;
    }
    .nameStyle {
      width: 50%;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      @media only screen and (max-width: 767px) {
        width: 80%;
      }
    }
  }
  .DateTimeContainer {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: 767px) {
      width: 81%;
    }
  }
  .deleteposticn {
    width: 5%;
    color: #333333;
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 28px;
    margin-bottom: 15px;
  }
}

// comment section=========================================
.communityTitleStyle {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  color: #34364a;
  margin-top: 10px;
}
.commentmaindiv0 {
  justify-content: start;
  display: flex;
  width: 100%;
  margin-top: 12px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .commentmaindiv {
    width: 40%;
    display: flex;
    align-items: center;
    cursor: pointer;
    @media only screen and (max-width: 1200px) {
      width: 55%;
    }
    .rightcomment {
      margin-left: 10px;

      p {
        margin-top: 5px;
        font-size: 16px;
        color: #34364a;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;

        line-height: 20px;
      }
    }
  }
  .likemaindiv {
    display: flex;
    cursor: pointer;
    margin-top: 1px;
    .rightlikes {
      p {
        margin-left: 10px;
        margin-top: -2px;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        color: #34364a;
      }
    }
  }
}
.child {
  width: 85%;
  float: right;
}
.deletepost {
  margin-left: 200px;
}
