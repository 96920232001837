.overirdeCalendar {
  .calContainer {
    height: 200px;
    box-shadow: 0px 4px 15px 0px #0000001a;
    border-radius: 1px;
    .table {
      width: 100%;
      padding: 20px;
      border: none;
      border-spacing: 0;
    }
    .tHead {
      background: #f5f5f5;
      border: 1px solid black;
      .thRow {
        th {
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
        }
      }
    }
    .tBody {
      .overrideClickableChips {
        .chip {
          border: none;
          height: 30px;
          width: 120px;
          background: #f0f0f0;
          border-radius: 16px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .trueSlot {
        .overrideClickableChips {
          .chip {
            span {
              color: #fff;
            }
            background: #5459ea;
          }
        }
      }
    }
  }
  .paginationContainer {
    border-radius: 26px;
    box-shadow: 0px 4px 15px 0px #0000001a;
    padding: 5px 15px;
    .paginationBtn {
      background: none;
      border: none;
      outline: none;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
    .paginationBtn:hover {
      cursor: pointer;
    }
    .paginationBtnActive {
      background: #5459ea;
      color: #fff;
    }
  }
}

.MuiDialog-paperWidthSm {
  .textButton {
    color: #778dff;
    font-weight: 600;
  }
}

.fc-toolbar {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
