.overrideCreateResume {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0;
  padding: 0;
  margin-top: 50px;
  p {
    margin: 0;
    font-family: "Montserrat";
  }
  .font16 {
    font-size: 16px;
  }
  .font24 {
    font-size: 24px;
  }
  .font14 {
    font-size: 14px;
  }
  .font700 {
    font-weight: 700;
  }
  .font400 {
    font-weight: 400;
  }
  .leftSidePanel {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    // width: 25%;
    // overflow-y: scroll;

    .resumeTemplate {
      height: auto;
      width: 200px;
    }
    .tabName {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 16px;
      color: #caced8;
      margin: 20px 0;
      max-width: 200px;
      cursor: pointer;
      &.active {
        background: rgba(119, 141, 255, 0.15);
        border-radius: 8px;
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 16px;
        color: #083a50;
        padding: 10px;
      }
    }
  }
  .rightPanel {
    // display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // width: 75%;
    margin: 0;
    // padding: 30px;
    // overflow-y: scroll;
    text-align: center;
    border: 2px solid rgba(232, 234, 238, 0.4);
    .socialIcons {
      width: 20px;
      height: auto;
    }
  }
  .resume-selector li {
    width: 75px;
    height: auto;
    margin: 10px auto;
    box-shadow: 0 0 5px;
    cursor: pointer;
    transition: all ease 0.5s;
  }
  .fit-layout {
    width: 100%;
    margin: auto;
  }
  .resume-selector li {
    width: 75px;
    height: auto;
    margin: 10px auto;
    box-shadow: 0 0 5px;
    cursor: pointer;
    transition: all ease 0.5s;
    list-style-type: none;
  }
  .this-resume {
    width: 150px !important;
    box-shadow: 0 0 10px !important;
    transition: all ease 0.5s;
  }
}
.createResumeBtn {
  margin-top: 35px;
  button {
    // box-shadow: rgba(63, 71, 90, 0.47) 3px 3px 8px 0px;
    border: transparent;
    padding: 11px 39px;
    font-size: 18px;
    border-radius: 7px;
    background-color: #778dff;
    color: #fff;
    margin-right: 10px;
    // margin: 15px auto;
    margin-bottom: 15px;
    cursor: pointer;
  }
}
