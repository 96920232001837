@import "../../scss/variables";

.body {
  margin: 0;
  padding: 0;
}
.Sidebar {
  width: 250px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  position: sticky;
  // height: 100vh;
  .sidesss {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fafafa;
    height: 97vh;
  }
  .logo_sidebaar {
    display: inline-block;
    padding: 30px 15px;
    img {
      max-width: 100px;
    }
  }
  .logo {
    margin-top: 30px;
    margin-bottom: 40px;
    // img {
    //   margin-left: 25px;
    // }
  }
  ul {
    padding: 0px;
    margin: 0px;
    li {
      padding: 7px 50px;
      margin: 15px 0px;
      display: flex;
      line-height: 30px;
      div#icon {
        width: 30px;
        height: 30px;
        line-height: 40px;
      }
      div#title {
        padding: 0px 15px;
        font-weight: 600;
        color: $shadow-grey;
        font-family: $font-Montserrat;
      }
    }
  }
}

.sidebarlist {
  height: auto;
  width: 100%;
}

.sidebarlist .rowli:hover {
  cursor: pointer;
  color: $text-black-color;
}
.Sidebar.sitelogo {
  position: absolute;
  width: 234px;
  height: 93px;
  left: 17px;
  top: 1rem;
}

.dashboard-title {
  position: absolute;
  width: 237px;
  height: 24px;
  left: 318px;
  top: 45px;
  color: $text-black-color;
}

.appMenu {
  .menuItem {
    // padding-left: 35px;
    justify-content: space-between;
    .menuText {
      // flex: auto;
      // padding-right: 5px;
      span {
        // color: $shadow-grey;
        // text-decoration: none;
        // font-weight: 600;
        // font-size: $font-size-16;
        // font-family: $font-Montserrat;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        // line-height: 17px;
        color: #2e3a59;
      }
    }
    .menuItemIcon {
      color: black;
      fill: black;
      min-width: 40px;
      // background: #2E3A59;
    }
  }
}
.Sidebar {
  text-align: center;
  .logo {
    .light-logo {
      display: block;
    }
    .dark-logo {
      display: none;
    }
  }
}

.sidesss {
  root {
    display: "flex";
  }
  .drawerPaper {
    width: 300px;
    background-color: transparent;
    border: none;
    z-index: 9;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: none;
      outline: 1px solid transparent;
    }
  }
  .content {
    flex-grow: 1;
    // height: "100vh";
    overflow: "auto";
  }
  .container {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.unreadMessages {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background: #f44336;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  margin-left: 5px;
}
