@import "../../../scss/variables";
.overridePAsswordField{
    
.searchcaseid {
    font-family: $font-Montserrat;
    border: 1px solid #caced8;
    background: #ffffff;
    border: 1px solid #caced8;
    border-radius: 8px;
    height: 44px;
  
    position: relative;
    .showIcon{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    input {
      border: none;
      width: 100%;
      outline: none;
      font-family: $font-Montserrat;
      font-size: $font-size-16;
    }
  
    input::placeholder {
      font-weight: 400;
      color: $manatee-color;
      font-size: $font-size-16;
    }
    input:active,
    input:focus {
      color: #043133;
      font-family: $font-Montserrat;
    }
  
    .inputRoot {
      &.Mui-disabled {
        background-color: white;
      }
    }
  }
}