.overrideClickableChips {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .chip {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 36px;
    background: #ffffff;
    span {
      height: 16px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      color: #333333;
      @media (max-width: 540px) {
        max-width: 250px;
      }
    }
  }
  .chip:hover {
    cursor: default;
  }
  .deleteUserChip {
    cursor: pointer;
    position: absolute;
    right: -7px;
    top: -10px;
  }
}
