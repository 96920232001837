.CommunityCardContainer {
  width: 100%;
  .CommunityCard {
    // padding: 25px 25px 35px 25px;
    padding: 10px;
    position: relative;
    // width: 550px;
    // height: 270px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    box-shadow: none;
    text-align: initial;
    .communityheader {
      display: flex;
      align-items: center;
      .communityicn img {
        border-radius: 4px;
        height: 48px;
        object-fit: cover;
        width: 48px;
      }
    }
    .CommunityName {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      line-height: 30px;
      text-align: initial;
    }
    .CommunitysName {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      line-height: 30px;
      text-align: initial;
      margin-left: 25px;
    }
  }
  .CommunityCard:hover {
    cursor: pointer;
  }

  .CommunityBtnStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .CommunityContent {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: initial;
  }
  .CommunityPersonCount {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
  }
  .ButtonStyle {
    background: #278fff;
    border-radius: 8px;
    width: 65px;
    height: 35px;
    text-transform: none;
  }
}
