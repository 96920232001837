.NodataDisplayedcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: auto;
  width: 100%;
  height: 315px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  padding: 5px;
  .NodataDisplayedcard {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
  }
  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .NodataDisplayedtext {
    text-align: center;
    margin-top: 20px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
  }
}
