@import "../src/scss/variables";

html,
body {
  font-family: "Montserrat" !important;
  color: $text-black-color;
  background: #ffffff;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.container {
  max-width: 1500px;
}

a {
  color: $cerulean-color;
}

a:hover {
  color: $cerulean-color;
}
.rightBar {
  width: 100%;
}
// //Test
// .dashboard {
//   .contentContainer {
//     display: flex;
//     justify-content: space-between;
//     padding-right: 40px;
//     @media (max-width: 767px) {
//       padding-right: 0px;
//     }
//     .rightBar {
//       width: 100%;
//       padding-left: 320px;
//       @media (max-width: 767px) {
//         padding-left: 0px;
//       }
//       .rightBar {
//         padding-left: 0px;
//       }
//       .container_wrapper {
//         padding: 20px;
//         margin-left: 20px;
//         min-height: 100vh;
//         @media (max-width: 767px) {
//           margin-left: 0px;
//         }
//         .dashTitle {
//           font-size: $font-size-24;
//           color: $text-black-color;
//           font-weight: 700;
//           font-family: $font-Montserrat;
//           margin-bottom: 50px;
//         }
//         .addShadowBox {
//           box-shadow: 0px 4px 50px rgb(0 0 0 / 7%);
//           border-radius: 8px;
//           padding: 20px;
//           margin-top: 40px;
//         }
//       }
//     }
//   }
// }

// .css-13r4j3i .MuiTextField-root {
//   margin: 0px !important;
//   width: 100% !important;
// }

// // grid
// .grid_main_sec {
//   text-align: left;
//   margin-top: 40px;
//   .grid_main_header {
//     margin-bottom: 15px;
//     .grid_heading_style {
//       font-family: $font-Montserrat;
//       font-weight: 700;
//       font-size: $font-size-24;
//       line-height: 29px;
//     }
//   }
//   .two_grid {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     align-items: center;
//   }
//   .grid_main_body {
//     padding: 0px;
//     margin: 0px;
//   }
// }
// .grid_main_sec:first-child {
//   margin-top: 0px;
// }

// // dashboard design
// .flex_main {
//   .Sidebar {
//     //position: absolute;
//     z-index: 9999;
//     left: 0;
//     top: 0;
//   }
//   .footer_sec {
//     border-top: 1px solid $fog-grey;
//     margin: 0px -15px 0px -30px;
//   }
//   .container {
//     max-width: calc(100% - 320px);
//     flex: 0 0 calc(100% - 320px);
//     padding-left: 30px;
//     padding-right: 15px;

//     .container_wrapper {
//       padding-left: 0px;
//       min-height: calc(100% - 70px);
//       .row_uniq {
//         margin-top: 30px;
//       }

//       .row_uniq:nth-child(1) {
//         margin-top: 0px;
//       }
//       .section_box {
//         max-width: 530px;

//         .viewcasetitile {
//           left: 0;
//           margin: 0;
//           position: inherit;
//           width: auto;
//           height: auto;
//           text-align: left;
//           padding-bottom: 10px;
//         }
//       }
//       .desc_box {
//         .viewinctitle {
//           position: inherit;
//           left: 0;
//           top: 0;
//           margin: 0;
//           padding: 0;
//           width: auto;
//           height: auto;
//           padding-bottom: 10px;
//           text-align: left;
//         }
//       }
//       // table style
//       .table_style_ui {
//         .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
//           border: none;
//           display: none;
//         }
//         .MuiDataGrid-columnHeaderTitleContainerContent {
//           .MuiDataGrid-columnHeaderTitle {
//             font-weight: 700;
//           }
//         }
//         .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
//           color: $border-grey;
//         }
//         .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
//           color: $cerulean-color;
//         }

//         .MuiDataGrid-root.MuiDataGrid-root--densityStandard {
//           border: none;
//           .MuiDataGrid-main {
//             border: 1px solid $border-grey;
//             margin-bottom: 30px;
//           }
//         }
//         .MuiDataGrid-footerContainer {
//           border: none;
//           position: relative;
//           top: -30px;
//         }
//       }
//     }
//   }
// }
// [data-theme="dark"] {
//   background-color: $black-color;
//   min-height: 100%;
//   .dashboard {
//     .contentContainer {
//       .rightBar {
//         .container_wrapper {
//           padding: 20px;
//           background: $davy-grey-color;
//           box-shadow: 0px 4px 90px rgb(0 0 0 / 10%);
//           margin-left: 20px;
//         }
//       }
//     }
//   }
//   .table_style_ui {
//     .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
//       color: $white-color;
//     }
//     .MuiIconButton-root {
//       color: $white-color;
//     }
//     .MuiTablePagination-displayedRows {
//       color: $white-color;
//     }
//     .MuiDataGrid-iconSeparator {
//       display: none;
//     }
//   }
// }

// .logo,
// .logos {
//   img {
//     min-width: 170px;
//   }
// }
.navigation {
  width: 250px !important;
  .MuiPaper-elevation {
    width: 250px !important;
    border: none;
  }
}
.MuiDrawer-paper {
  width: 250px !important;
  border: none;
}
.rightTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 30px;
  padding: 20px;
  margin-top: 10px;
}
@media (max-width: 540px) {
  .rightTop {
    padding: 15px;
    width: 104%;
  }
}
