.overRideSearch {
  p {
    margin: 0;
  }
  .searchGrid{
    height: 400px;
    @media (max-width: 1280px) {
      width: 700px;
    }
    @media (max-width: 1024px) {
      width: 700px;
    }
    @media (max-width: 992px) {
      width: 450px;
    }
    @media (max-width: 540px) {
      width: 300px;
    }
  }
  .radioColor {
    // svg{
    //   color: #333333;
    // }
  }
  .filterLabel {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
  .recentSearchContainer {
    max-height: 380px;
    overflow-y: scroll;
    .cardBody {
      background: #f7f9fc;
      border-radius: 8px;
      .card {
        p {
          margin: 0;
          font-family: "Montserrat";
        }
        .name {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #2e3a59;
        }
        .adress {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8f9bb3;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
    h1 {
      margin: 0;
    }
  }
}

.overrideMap {
  .popup {
    // .mapboxgl-popup-content {
    background: #f7f9fc;
    border-radius: 8px;
    box-shadow: none;
    padding: 0;
    width: max-content;
    p {
      margin: 0;
    }
    .card {
      width: max-content;
    }
    .name {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #2e3a59;
    }
    .adress {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #8f9bb3;
    }
  }
  // }
}


.overrideMap{
  height: 100%;
  width: 100%;
  .leaflet-container {
    height: 90%;
    width: 100%;
    border-radius: 2rem;
  }
}