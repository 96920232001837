.overRideSettings {
  width: 100%;
  @media (max-width:600px) {
    margin-left: -2%;
  }
  .manageCredentials {
    border-radius: 15px;
    width: 100%;
    padding: 5px 26px;
    @media (max-width:600px) {
      padding: 0;
    }
    .verificationChip {
      height: 28px;
      background: #34a853;
      border-radius: 100px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 8px;
      margin-left: 35px;
      @media (max-width: 1200px) {
        margin-left: 0px;
      }
    }
    .notverified {
      background: #e82127;
    }
  }
  .paymentHistory {
    width: 100%;

    border-radius: 15px;
    padding: 5px 26px;
    @media (max-width:600px) {
      padding: 0;
    }
    .table table {
      border: 1px solid #666666;
      // width: 820px;
      height: 230px;
      width: 100%;
      border-collapse: collapse;
      .table table,
      th {
        width: 150px;
        height: 20px;
        border-bottom: 1px solid #666666;
        border-left: 1px solid #666666;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 122% */

        /* Primary/One */

        color: #083a50;
      }
      .table table,
      td {
        width: 162px;
        height: 20px;
        border-bottom: 1px solid #666666;
        border-left: 1px solid #666666;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 122% */

        color: #333333;
      }
    }
  }
  .compensationData {
    width: 100%;

    border-radius: 15px;
    padding: 5px 26px;
    @media (max-width:600px) {
      padding: 0;
    }
    .currentSalary {
      font-family: "Montserrat";
      font-weight: 800;
      font-size: 20px;
      color: #2e3a59;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .compensationSlider {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .deactivateAccount {
    width: 100%;

    border-radius: 15px;
    padding: 5px 26px;
    .paraFont {
      font-family: "Montserrat";

      font-weight: 400;
      font-size: 16px;

      color: #333333;
      @media (max-width:600px) {
        text-align: left;
      }
    }
    .paraFont .clickLink {
      font-weight: 700;
      color: #e82127;
      cursor: pointer;
    }
  }
}

.overRideSettings {
  .label {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
    color: #083a50;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .password {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }
  .saveOption {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .cancelBtn .overideBtn .containedButton {
      width: 89px;
      height: 41px;

      background: #f0f0f0;
      border-radius: 8px;
      font-family: "Montserrat";

      font-weight: 600;
      font-size: 14px;

      color: #2e3a59;
    }
  }
  .settingHeading {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    display: flex;
    justify-content: flex-start;
  }
  .greyBtn .overideBtn .containedButton {
    width: 100%;
    height: 41px;
    background: #eeeeee;
    border-radius: 8px;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    color: #666666;
    margin-top: 8px;
    .editCred {
      display: flex;
    }
  }
  .inlineRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .saveBtn .overideBtn .containedButton {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    height: 41px;
    background: #778dff;
    border-radius: 8px;
  }
  .verifyNow .overideBtn .textButton {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    color: #778dff;
    text-transform: capitalize;
    margin-top: 3px;
  }
  .table table {
    border: 1px solid #666666;
    width: 100%;
    border-collapse: collapse;
    margin-top: 25px;
    .table table,
    tr {
      width: 150px;
      height: 40px;
    }
    .table table,
    th {
      width: 150px;
      height: 20px;
      border-bottom: 1px solid #666666;
      border-left: 1px solid #666666;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 122% */

      /* Primary/One */

      color: #083a50;
    }
    .table table,
    td {
      width: 162px;
      height: 74px;
      border-bottom: 1px solid #666666;
      border-left: 1px solid #666666;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      .deleteMember {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.salary-bar.in-form {
  margin-top: 15px;
}

.salary-bar {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(to right, #00adff, green);
  position: relative;
  margin-top: 15px;
  .fa {
    position: absolute;
    top: -20px;
    left: -8px;
    font-size: 3em;
    z-index: 123;
  }
}
.slider-box {
  position: absolute;
}

.salary-meter {
  display: table;
  width: 100%;
  margin-top: 5px;
}
.salary-meter li {
  display: table-cell;
  text-align: center;
  width: 20%;
  font-size: 0.9em;
}
.error {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}
