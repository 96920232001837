@import "../../../scss//variables";
.overrideJobCard{
    width: 100%;
    .card{
        width: 100%;
        width: 700px;
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        cursor: pointer;
        @media (max-width:600px) {
            width: 100%;
        }
        p{font-family: $font-Montserrat;margin: 0;padding: 0;}
        .font700{
            font-weight: 700;
        }
        .font400{
            font-weight: 400;
        }
        .shadow-grey{
            color: $shadow-grey;
        }
        .blackText{
            color:  #333333;
        }
        .font18{
            font-size: 18px;
        }
        .font16{
            font-size: 16px;
        }
        .font12{
            font-size: 12px;
        }
        .partitian{
            width: 100%;
            border: 1px solid #EBEBEB;
        }
    }
}