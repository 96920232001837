.radioColor {
  span {
    text-decoration: none;
  }
  .css-hyxlzm {
    font-weight: 400;
    font-size: 18px;
    // color: #778dff;
  }
  .MuiFormControlLabel-label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252733;
  }
  .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252733;
  }
}

@media (max-width: 540px) {
  .radioColor {
    .MuiTypography-root {
      font-size: 13px;
    }
  }
}
