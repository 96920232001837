.chatBox {
  .leftChatBox {
    border-right: 1px solid #eeeeee;
    padding-right: 32px;
    width: 100%;
    .topChatHeader {
      .chatSearchBox {
        margin-top: 20px;
        .overideSearchAuto {
          .formControlRoot {
            .MuiInputBase-root {
              border-radius: 8px;
              padding: 0px 20px 0px 40px;
              .MuiAutocomplete-endAdornment {
                right: inherit;
                left: 15px;
              }
            }
          }
        }
      }
      h2 {
        color: #333333;
        font-size: 24px;
        font-family: "Montserrat";
        font-weight: 600;
        text-align: left;
        margin: 15px 0px 0px 0px;
      }
    }
    .conversationTypeRadio {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .rightBox {
    height: auto;
    margin-top: 30px;
    .noChat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-right: 8px;
      .conversationTitle {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .chatListBox {
    height: 74vh;
    overflow-x: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // outline: 1px solid #D9D9D9;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
  }
}
.chatBox {
  .leftChatBox {
    .topChatHeader {
      .chatSearchBox {
        .overideSearchAuto {
          .formControlRoot {
            &.MuiAutocomplete-hasClearIcon {
              .MuiInputBase-root {
                padding: 0px 20px 0px 65px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .chatBox {
    .leftChatBox {
      border-right: none;
      padding-right: 10px;
    }
    .chatListBox {
      overflow-y: scroll;
      // overscroll-behavior: contain;
      height: auto;
    }
  }
}
