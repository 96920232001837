.overrideSearch {
  .pageHeading {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
  }
  .filterLabel {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
  }
  .overideSelectMenu .selectBox .MuiSelect-select {
    font-weight: 700;
  }
  .overideBtn .textButton {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
  }
}
