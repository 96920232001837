.MuiDialog-container {
  .errMsg{
    font-size: 12px;
    color: #f21d1d;
    margin: 0;
  }
  .reqBtn{
  .overideBtn {
    width: 100%;
    .containedButton {
      width: fit-content;
      // max-width: 320px;
      color: #ffff;
      background: #278fff;
      border-radius: 8px;
      @media (max-width:600px) {
        width: 100%;
      }
    }
  }}
  .slotBtn{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
    background: #EEEEEE;
    box-shadow: none;
    width: 100%;
    justify-content: space-between;
    box-shadow: none;
  }
  .slotBtn:hover{
    background: #EEEEEE;
    box-shadow: none;
  }
  .selectedSlotBtn{
    border: 1px solid #333333;
  }
  .modalPara {
    width: 100%;
    // min-width: 400px;
    text-align: center;
    margin-top: 15px;
    overflow: hidden;
  }
  .saveBtn{
    .overideBtn .containedButton {
      width: fit-content;
    }
  }

  .MuiDialog-paperWidthSm {
    border-radius: 15px;
    background: #ffffff;
    width: 700px;
    // padding: 50px;
    height: fit-content;
    .errorMSg{
      margin-bottom: -20px;
      color: #f21d1d;
      font-family: "Montserrat";
      font-size: 12px;
      margin: 0;
      }
    .Dioerrimg {
      overflow-y: scroll;
      
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
    }
    .errimg img {
      width: 33.33px;
      height: 33.33px;
      padding-left: 172px;
      padding-top: 12px;
    }
  }
}
.MuiDialogContent-root {
  overflow: hidden;
}


.dialogBoxOver{.errorMSg{
margin-bottom: -20px;
color: #f21d1d;
font-family: "Montserrat";
font-size: 12px;
margin: 0;
}}