.MuiGrid-root {
  .MuiGrid-container {
    .MuiGrid-item {
      // height: max-content;
    }
  }
}
.mycommunityGrid {
  height: max-content;
}
.recentposttitle {
  width: 144px;
  height: 22px;
  // left: 293px;
  top: 774px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.input_field_box .MuiFormControl-root .Mui-error {
  margin-top: 9px;
}
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium {
  width: 21px;
  height: 20px;
}
.posttitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #083a50;
  float: left;
}

.MuiGrid-root {
  .MuiGrid-grid-lg-3 .addcomments {
    float: right;
    @media only screen and (max-width: 1200px) {
      float: left;
    }
  }
 
}
.required {
  color: #f21d1d;
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 1024px) {
}

// @media only screen and (max-width: 767px) {
.CommunityCardContainer {
  //  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.communityCards {
  height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}
// }
.addBox {
  .overideBtn {
    .outlinedButton {
      width: 100%;
      max-width:none
    }
  }
}

.mycommunityGrid {
  @media only screen and (max-width: 1200px) {
    order: -1;
  }
}
.createPostStyle {
  @media only screen and (max-width: 1200px) {
    float: left;
  }
}
.addcomments{
  float: right;
  @media only screen and (max-width: 1200px) {
    float: left;
  }
}
