@import "../../../scss/variables";
.TrendingCommunityCardContainer {
  .Card {
    position: relative;
    width: 100%;
    // height: 88px;
    background: #f7f9fc;
    border: 1px solid #f0f0f0;
    box-shadow: none;
    font-family: $font-Montserrat;
    cursor: pointer;
    .cardImage {
      position: absolute;
      width: 50px;
      height: 50px;
      //background: #778dff;
      // border-radius: 8px;
      margin: 15px 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        // height: 21px;
        // width: 29px;
        height: 49px;
        width: 49px;
        border-radius: 4px;
        border: 1.5px solid #ffffff;
      }
    }
    .columns {
      margin-left: 70px;
      margin-top: 1px;
      text-align: start;
      overflow: hidden;
      flex-direction: row;
    }
    .listViewName {
      font-weight: 700;
      font-size: 16px;
      color: #2e3a59;
      font-family: $font-Montserrat;
    }
    .listViewRole {
      font-weight: 500;
      font-size: 14px;
      color: #2e3a59;
      font-family: $font-Montserrat;
    }
    .right {
      display: flex;
      flex-direction: row;
    }
  }
}
