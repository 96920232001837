@import "../../../scss/variables";

.overideInputField {
  width: 100%;
  .input_field_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 5px;
    // max-width: 500px;
    color: #083a50;
    .formLable {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 16px;
      color: #083a50;
      margin-bottom: 5px;
    }
    .formControlRoot {
      width: 100%;
      // height: 44px;
      background: #ffffff;
      border: 1px solid #caced8;
      border-radius: 8px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      // padding: 10px 22px;
      // padding: 0 0 0 10px;
      height: 44px;
      .MuiFormHelperText-root {
        margin-bottom: -20px;
        color: #f21d1d;
        font-family: $font-Montserrat;
        font-size: 12px;
      }
      .inputRoot {
        height: 100%;
        // margin-left: 10px;
        padding: 0 5px;
        font-family: $font-Montserrat;
        color: #083a50;
      }
    }
  }
}
