.chatSections {
  .conversations {
    height: 57vh;
    overflow-y: scroll;
    display: block;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
    .sentMsgssss {
      display: flex;
      justify-content: flex-end;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      .sendMessag {
        width: 100%;
        max-height: fit-content;
        height: fit-content;
        width: fit-content;
        background: #278fff;
        border-radius: 40px 40px 0px 40px;
        padding: 24px 20px;
        color: #ffff;
        text-align: right;
        align-items: right;
        float: right;
      }
    }
    .receivedMsgssss {
      width: 100%;
      max-width: fit-content;
      max-height: fit-content;
      height: fit-content;
      width: fit-content;
      background: #f8f8ff;
      border-radius: 40px 40px 40px 0px;
      padding: 10px 20px;
      color: #666666;
      text-align: left;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
    .sendMessageDates {
      // font-family: "Montserrat";
      font-weight: 600;
      font-size: 14px;
      color: #666666;
      text-align: right;
      margin-bottom: 20px;
      margin-top: 5px;
      width: 100%;
    }
    .receiveMessageDate {
      // font-family: "Montserrat";
      font-weight: 600;
      font-size: 14px;
      color: #666666;
      text-align: left;
      margin-top: 5px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  .messageBox {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 40px;
    width: 100%;
    padding: 16px 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    .messageAttchments {
      display: flex;
      gap: 13px;
      cursor: pointer;
    }
  }
}

.overrideChat {
  .chatBox {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    // padding: 10px;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .overrideChatInputField {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 40px;
      width: 100%;
      padding: 5px 20px;
      .voiceIcon {
        display: flex;
      }
      .messageIcons {
        display: flex;
        justify-content: flex-end;
      }
      .messageInput {
        .overideTextArea {
          width: 120%;
        }
        .textArea {
          width: 100%;
          border: none;
          width: 100%;
          padding: 16px 20px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          outline: none;
          // text-align: center;
          height: 53px !important;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
          }
        }
      }
      .messageInput:focus-visible {
        border: none;
      }
    }
  }
}

.overrideChat {
  padding: 0px 30px;
  .overrideMessageUserCard {
    width: 100%;
  }
}
.showOverRideChat {
  visibility: visible;
}
.file-input {
  margin-top: 13px;
}
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  // display: inline-flex;
  // align-items: center;
  // border-radius: 4px;
  // font-size: 14px;
  // font-weight: 600;
  // color: #fff;
  // font-size: 14px;
  // padding: 10px 12px;
  // background-color: #4245a8;
  // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.imageBoxSender {
  width: 100%;
  display: flex;
  justify-content: end;
}
.imageBoxReceiver {
  width: 100%;
  display: flex;
  justify-content: start;
}
.image {
  width: 150px;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
}

@media (max-width: 540px) {
  .overrideChat {
    padding: 0px;
    .chatBox {
      .overrideChatInputField {
        width: 100%;
        padding: 0;
        margin: 0 10px 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .messageInput {
          .overideTextArea {
            width: 100%;
          }
        }
      }
    }
  }
  .chatBox {
    .rightBox {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: 10px;
    }
  }
}
