@import "../../../../src/scss//variables";

.overrideDataGrid {
  &[aria-rowindex="1"]{
    // background: red;
  }
  .MuiDataGrid-columnHeaders{
    background: rgb(189, 223, 230);
  }
  .MuiDataGrid-columnHeaders {
    font-family: $font-Montserrat;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    color: $text-black-color;
  }

  // .MuiSvgIcon-root {
  //   font-size: $font-size-30;
  // }
  @media (min-width: 600px) {
    .MuiDataGrid-selectedRowCount {
      visibility: hidden;
    }
  }
}
.table_style_ui {
  .overrideDataGrid {
    .MuiDataGrid-row:hover {
      background-color: $gainsboro;
    }
  }
}
