.overrideManageMentorship {
  .pageName {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    color: #333333;
  }
  .calendorBtnGrid {
    .overideBtn {
      .containedButton {
        width: 200px;
        height: 40px;
        border-radius: 23px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
