.headerRes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.overrideSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    .leftMar{
      margin-left: -5px;
    }
    .subMenu{
      font-size: 12px;
      .MuiListItemText-root {
        span {
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          color: #2e3a59;
          text-transform: capitalize;
          cursor: pointer;   
          margin-left: 10px;
        }
      }
    }
            
    li {
      padding: 0;
      .MuiListItemText-root {
        span {
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 600;
          color: #2e3a59;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
    }
  }
  .light-logo {
    width: 150px;
    height: auto;
    margin-top: 25px;
    cursor: pointer;
  }
  .css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
    box-sizing: border-box;
    width: 240px;
    border: none;
    background: #fafafa;
  }
}

.appbarContainer {
  .toolbar {
    background-color: #ffffff;
    box-shadow: none;
    .headerRes {
      font-family: "Montserrat";
      background: "#fafafa";
      margin-left: -15px;
      margin-top: 25px;
      border-radius: 25px 25px 0 0;
      @media (max-width:960px) {
        margin-top: 0;
      }
      .welcomeUser {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 24px;
        margin-left: 15px;
        color: #000000;
      }
    }
  }
}
@media (max-width: 540px) {
  .appbarContainer {
    .toolbar {
      // .appbar {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      //   justify-content: center;
      //   text-align: center;
      // }
      .headerRes {
        // margin-bottom: 20px;
        padding: 10px;
        font-size: 20px;
        .welcomeUser {
          font-size: 20px;
          margin-left: 15px;
        }
      }
    }
  }
}
