@import "../../../scss/variables";

.overidesignup {
  height: 99.6vh;
  .leftPic {
    width: 100%;
    height: 99.6vh;
    object-fit: cover;
    object-position: center;
  }
  .logobutton {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid #caced8;
    background-color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }

  // .logo {
  //   width: 45px;
  //   height: 35px;
  //   margin-right: 10px;
  // }

  .buttontext {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #043133;
  }

  .already-signup-txt {
    color: #666666;
    font-family: "Montserrat";
    text-align: left;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .login {
    color: #1b436e;
    font-size: 14px;
    font-weight: 700;
    // margin-right: 2px;
    text-decoration: none;
    font-family: "Montserrat";
    cursor: pointer;
  }

  .verify-otp {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #caced8;
    border: 1px solid #caced8;
    border-radius: 8px;
    height: 44px;
    padding-left: 12px;
    ::placeholder {
      color: #caced8;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .show-danger {
    color: #043133;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    cursor: pointer;
  }

  .forgot-password {
    color: #043133;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    cursor: pointer;
  }
  .resendOtp {
    display: flex;
    justify-content: left;
    // padding-right: 60px;
  }
}
