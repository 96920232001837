.overrideSessionCArd {
  padding: 20px 40px;
  background: #f7f9fc;
  border-radius: 8px;
  max-width: 170px;
  p {
    margin: 0;
    font-family: "Montserrat";
  }
  .name {
    color: #2e3a59;
  }
  .designation {
    color: #8F9BB3;
  }
  .font600 {
    font-weight: 600;
  }
  .font400 {
    font-weight: 400;
  }
  .font12 {
    font-size: 12px;
  }
  .font10 {
    font-size: 10px;
  }
  .dateTime{
    color: #000000;
  }
}
