.filterlist_of_cases {
//   .label {
    .MuiTypography-root{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #5f5f5f;
}
.MuiFormControlLabel-root{
.Mui-checked{
    color: #5061FF;
}
svg{
    border: #5061FF;
}
}
//   }
}
