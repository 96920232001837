@import "../../../scss/variables";

.overrideViewProfile {
  @media (max-width:600px) {
    margin-right: 8%;
  }
  .coverPicGrid {
    // height: 285px;

    .coverpic {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      background: transparent;
      max-height: 300px;
    }
  }
  .profileHeader {
    background: #f7f9fc;
    width: 100%;
    align-items: flex-start;
    .userName {
      display: flex;
      gap: 5px;
      .mentorIcon {
        height: 30px;
        margin-top: 2px;
      }
    }
    .leftDiv {
      .imageAndContactDetails {
        .img {
          .profileImg {
            border-radius: 8px;
          }
        }
      }
    }
  }
  .socialIconsDiv, .socialIconsDivCorp {
    .socialIcons {
      width: 22px;
    }
    .twitterIcon {
      width: 27px;
    }
    .pintrestIcon {
      width: 30px;
      margin-top: -5px;
      margin-left: -5px;
    }
  }

  p {
    margin: 0;
    font-family: "Montserrat";
  }
  .font400 {
    font-weight: 400;
  }
  .font700 {
    font-weight: 700;
  }

  .font800 {
    font-weight: 800;
  }
  .font24 {
    font-size: 24px;
  }

  .font20 {
    font-size: 20px;
  }
  .font16 {
    font-size: 16px;
  }
  .font14 {
    font-size: 14px;
  }

  .dotGreen {
    color: #0d8e6d;
    // height: 12px;
    // width: 12px;
    // border-radius: 50%;
    // margin-top: 3px;
  }
  .dotRed {
    color: #8e0d0d;
    // height: 12px;
    // width: 12px;
    // border-radius: 50%;
    // margin-top: 3px;
  }
  .lineHeight0 {
    line-height: 0;
  }
  .lineHeight20 {
    line-height: 30px;
  }
  .switchLabel {
    font-family: $font-Montserrat;
    color: $Eerie-Black;
    font-weight: 500;
    font-size: $font-size-14;
    // text-transform: uppercase;
    margin-top: 2px;
  }
  .sectionHeading {
    color: #2e3a59;
    display: flex;
    justify-content: space-between;
  }
  .aboutEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }
  .aboutEdit:hover {
    .editIcon {
      display: block;
    }
  }
  .expEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }
  .expEdit:hover {
    .editIcon {
      display: block;
    }
  }
  .eduEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }
  .eduEdit:hover {
    .editIcon {
      display: block;
    }
  }
  .skillEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }

  .skillEdit:hover {
    .editIcon {
      display: block;
    }
  }
  .toolsEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }
  .toolsEdit:hover {
    .editIcon {
      display: block;
    }
  }

  .indEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }
  .indEdit:hover {
    .editIcon {
      display: block;
    }
  }

  .mentEdit {
    .editIcon {
      // display: none;
      button {
        color: #2e3a59;
        padding: 0;
      }
    }
  }

  .mentEdit:hover {
    .editIcon {
      display: block;
    }
  }
  .sectionDetails {
    color: #000000;
  }
  .educationCard {
    border: 1px solid rgba(102, 102, 102, 0.25);
    border-radius: 8px;
  }
}

.profileHeader {
  @media (max-width: 1180px) {
    .workingDetails {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .profileCompletionSlider1 {
      .editBtns {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  @media (max-width: 1200px) {
    .workingDetails {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
    }
    .profileCompletionSlider1 {
      .editBtns {
        width: 100%;
        display: flex;
        justify-content: left;
      }
    }
  }

  @media (max-width: 540px) {
    .font16 {
      font-size: 12px;
    }
    .font14 {
      font-size: 12px;
    }
    .font400 {
      font-weight: 400;
    }
    .font700 {
      font-weight: 700;
    }

    .leftDiv {
      .imageAndContactDetails {
        display: flex;
        justify-content: center;

        .contactDetails {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          // margin-left: 27px;
          margin: -26px;

          width: 100%;
          .userDetails {
            text-align: center;
            align-items: center;
          }
          .userInfo {
            width: 100%;
            justify-content: center;
          }
        }
      }

      .font24 {
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        align-items: center;
      }
      .font400 {
        font-size: 14px;
        text-align: center;
        align-items: center;
        font-weight: 400;
      }
      .Icons {
        width: 15px;
      }
      .toggleBtn {
        display: none;
      }
    }
    .profileCompletionSlider1 {
      .editBtns {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .socialIconsDiv  {
      position: absolute;
      display: flex;
      justify-content: center;
      margin-top: 90%;
    }

    .centerDiv {
      @media (max-width:540px) {
        display: none;
      }
      .sliders {
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        margin-top: 56px;
        .profileCompletionSlider {
          width: 100%;
          .font16 {
            text-align: center;
          }
        }
        .compensationSlider {
          width: 100%;
          .compensationSlide {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .font16 {
            text-align: center;
          }
        }
      }
      .workingDetails {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .workingDetails1 {
        .font16 {
          text-align: center;
        }
      }
    }
  }
  @media (max-width:440px) {
    .socialIconsDiv {
      margin-top: 80%;
      
    }
  }
  @media (max-width:420px) {
    .socialIconsDiv {
      margin-top: 85%;
      
    }
  }
  @media (max-width:400px) {
    .socialIconsDiv {
      margin-top: 95%;
      
    }
  }
  @media (max-width:365px) {
    .socialIconsDiv {
      margin-top: 97%;
      
    }
  }
}

.smLinksExperiance {
  .socialIconss {
    height: 30px;
    width: 30px;
  }
}
