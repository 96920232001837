@import "../../../scss/variables";

.successScreenBody {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .succesImg {
    width: 90px;
    height: 90px;
  }
  &.passed {
    max-width: 460px;
    margin: auto;
    .succesImg {
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
    .report {
      color: $green-color;
      font-size: $font-size-24;
      font-family: $font-Montserrat;
      font-weight: 700;
      margin: 10px 0px;
    }
    .generated {
      color: $text-black-color;
      font-size: $font-size-24;
      font-family: $font-Montserrat;
      font-weight: 600;
    }
  }
  &.failed {
    max-width: 460px;
    margin: auto;
    .succesImg {
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
    .report {
      color: $nobg;
      font-size: $font-size-24;
      font-family: $font-Montserrat;
      font-weight: 700;
      margin: 10px 0px;
    }
    .generated {
      color: $text-black-color;
      font-size: $font-size-24;
      font-family: $font-Montserrat;
      font-weight: 600;
    }
  }
  .heading {
    // color: $text-black-color;
    // font-size: $font-size-24;
    // font-family: $font-Montserrat;
    // font-weight: 700;
    // line-height: 28px;
    // margin: 30px 0px 45px 0px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    color: #043133;
  }
  .report {
    // color: $shadow-grey;
    // font-size: $font-size-14;
    // font-weight: 700;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    color: #043133;
  }
  .CNU {
    color: $black-color;
    font-size: $font-size-16;
    font-weight: 700;
  }
  .generated {
    // color: $shadow-grey;
    // font-size: $font-size-14;
    // font-weight: 700;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    color: #043133;
  }
  .redirect {
    color: $spanish-gray;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 26px;
    span {
      color: $text-black-color;
      font-size: $font-size-14;
    }
  }
}
