.viewJobContainer {
  // background: #FAFAFA;
  .MainHeadingDetails {
    color: #333;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
  }

  .Viewcontainer {
    .box {
      display: flex;
    }
    .left-section {
      width: 65%;
      border-right: 1px solid #999999;
    }
    .right-section {
      width: 30%;
    }
  }

  .card {
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
    max-width: 95%;
    margin: auto;
    text-align: center;
    font-family: arial;
    border-radius: 10px;
    height: 150px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .JobHeadingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .JobSubHeadingDeatails {
    color: #333;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
  }
  .threDot {
    width: 24px;
    height: 24px;
  }
  .CompanyName {
    color: #666;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    padding-left: 30px;
    padding-top: 9px;
  }
  .AdressStyle {
    color: #666;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding-left: 30px;
    padding-top: 14px;
  }
  .PostDateContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    .PostDate{
        color: #666;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 400;
        & span{
            color: #333;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .AplicantNo{
        color: #5459EA;
        text-align: right;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
  }
}
