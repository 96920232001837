@import "../../../scss/variables";

.searchid {
  position: relative;
  // max-width: 100%;
  width: 100%;
  // flex: 50%;
  display: flex;
  font-family: $font-Montserrat;

  label {
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 5px 0px;
    span {
      width: 50px;
      svg {
        font-size: $font-size-30;
        color: #999999;
      }
    }
    div {
      input {
        font-family: "Montserrat";
        width: 100%;
        max-height: 50px;
        padding: 0px;
        font-size: $font-size-16;
        outline: none;
        border: none;
        height: 45px;
      }

      input:active,
      input:focus {
        color: #828996;
      }
    }
  }
  .serchInputField {
    width: 85%;
    height: 100%;
    border: none;
    outline: none;
  }
  input::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #999999;
  }
}

.overideSearchAuto {
  width: 100%;
  outline: none;
  .formControlRoot {
    background: $white-color;
    border-radius: 15px;
    // font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
    min-height: 44px;
    .MuiOutlinedInput-root {
      padding: 3px;
      // font-family: $font-lato;
    }
    .Mui-disabled {
      background-color: $fog-grey;
    }
    .MuiAutocomplete-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall{
      border-radius: 8px;
    }
    .muioutlinedinput-notchedoutline {
      border-radius: 15px;
      padding: 3px 15px;
    }
    .MuiInputBase-root {
      min-height: 44px;
      border-radius: 8px;
    }
  }
  .inputroot {
    border-radius: 16px;
    padding: 8px 15px;
  }
  .Mui-error {
    margin-left: 0px;
    .MuiOutlinedInput-notchedOutline {
      border-color: $border-grey !important;
    }
  }
}

[data-theme="dark"] {
  .overideSearchAuto {
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      .Mui-disabled {
        background-color: $black-color;
      }
      input {
        color: $saphire-blue-color;
      }
    }
  }
}
