.footer-top ul {
  margin: 0;
  padding-left: 90px;
  padding-right: 90px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-top ul li a {
  text-decoration: none;
  cursor: pointer;
}
.footer-logo {
  display: flex;
  justify-content: flex-start;
  width: 267px;
  height: 114px;
  padding-left: 90px;
  // img {
  //   width: 100%;
  //   height: 100%;
  // }
}
.rightstyle {
  color: #5c5e87;
  font-size: 20px;
  font-family: "Montserrat";
}
.aboutUsStyle {
  color: #2b3377;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 500;
}
.tcStyle {
  color: #2b3377;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 500;
  
}
.privacyStyle {
  color: #2b3377;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 500;
}

.footer-bottom ul {
  margin: 0;
  padding-left: 90px;
  padding-top: 40px;
  padding-bottom: 30px;
  gap: 25px;
  list-style: none;
  display: flex;
  align-items: center;
}
