.overrideDashboard {
  .searchuserConatiner {
    margin-top: -10px;
    // z-index: 999999;
  }
  .SearchPeopleStyle {
    @media (max-width: 907px) {
      margin-top: 20px;
    }
  }
  .cardBody {
    border: 1px solid #d9d9d9;
    background: #fff;
    border-top: none;
    .searchCardContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      .nameAdressContainer {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 460px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
    .dot {
      @media only screen and (min-width: 460px) {
        width: 4px;
        height: 4px;
        background: #000000;
        border-radius: 50%;
        // padding: 6px;
        margin: 25px;
      }
    }
    .name {
      font-family: "Montserrat";
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      color: #2e3a59;
    }
    .adress {
      font-family: "Montserrat";
      // padding-top: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8f9bb3;
    }
  }
  .cardBody:hover {
    cursor: pointer;
  }
  .recenetSearchHeading {
    margin-bottom: 5px;
  }
  .recentSearchesContainerStyle {
    grid-gap: 35px;
  }
  .recentSearchContainer {
    max-height: 350px;
    overflow-y: scroll;
    padding: 7px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
    h1 {
      margin: 0;
    }
  }
  .profileGrid {
    text-align: "left";
    background: #f7f9fc;
    border-left: 1px solid #eeeeee;
    border-radius: 0px 26px 0px 0px;
    // height: 800px;
    @media only screen and (max-width: 1200px) {
      order: -1;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .marketGrid {
    // height: 331px;
    left: 266.16px;
    border: 1px solid #eeeeee;
    border-radius: 8px;
  }
  .newsGrid {
    // height: 331px;
    // left: 266.16px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 35px 20px;
  }
  .NonewsGrid {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
  }
  .NoDataToshowStyle {
    padding-left: 10px;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    margin-block: 0;
    margin: 0;
  }
  .cardStyle {
    margin-top: 10px;
  }
  .link {
    text-decoration: none;
  }
  .seeAllButton {
    float: right;
    width: 74.35px;
    height: 22px;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    cursor: pointer;
  }

  .CommuntityLeftSideCard {
    @media (min-width: 1200px) and (max-width: 1920px) {
      padding-left: 24px;
      padding-top: 65px;
    }
    @media only screen and (max-width: 1200px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 1200px) {
      order: -1;
    }
  }
  .mycommunityHeadnigStyle {
    padding-top: 8px;
    padding-left: 8px;
  }
}
