.MainHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  text-align: left;
}

.jobSubHeading {
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  text-align: left;

}

.SubHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  text-align: left;
}

.container {
  .box {
    display: flex;
  }

  .left-section {
    width: 58%;
    border-right: 1px solid #999999;
  }

  .right-section {
    width: 42%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #000000;

    .subHeadingContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .viewJosStyle {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #333333;
        cursor: pointer;
      }
    }
  }

  .selectLabels {
    display: flex;
    justify-content: flex-Start;
    align-items: flex-Start;
    height: 190px;
  }

  .selectLabel {
    display: flex;
    justify-content: flex-Start;
    align-items: flex-Start;
  }

  .radiostyle {
    span {
      text-decoration: none;
    }

    .css-hyxlzm {
      font-weight: 400;
      font-size: 18px;
      // color: #778dff;
    }

    .MuiFormControlLabel-label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #083a50;
    }

    .MuiTypography-root {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #083a50;
    }
  }

  .Buttonjob {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid #b8bbd2;
    background-color: #f0f0f0;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    color: #2e3a59;
  }
}

.saveBtnstyle {
  .OverridesaveBtn {
    background: #778dff;
    border-radius: 8px;
    font-weight: 600;
    text-transform: none;
    color: #ffffff;
  }

  .OverridesaveBtn:hover {
    background-color: #778dff;
    text-decoration: none;
  }
}

.cancelBtnstyle {
  .overrideCancelbutton {
    background: #f0f0f0;
    border-radius: 8px;
    text-transform: none;
    color: #2e3a59;
    font-weight: 600;
  }

  .overrideCancelbutton:hover {
    text-decoration: none;
    background-color: #f0f0f0;
  }
}

.lebelText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #083a50;
}

.toggleStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .MuiTypography-root {
    padding-left: 10px;
  }
}