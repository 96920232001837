@import "../../../scss/variables";

.overideTextArea {
  .textAreaBody{
    display: flex;
  }
  .textArea {
    width: 100%;
    height: 200px;
    max-height: 202px;
    // max-width: 500px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid #CACED8;
    border-radius: 8px;
    background-color: $white-color;
    font-size: $font-size-16;

    resize: none;
    width: 100%;
    resize: none;
    font-family: $font-Montserrat;
    color: #083a50;
    &:focus-visible {
      border: 1px solid #CACED8;
      outline: none;
    }
  }
  .errorText {
    color: $red-color;
    margin-left: 0px;
  }
}

[data-theme="dark"] {
  .textArea {
    background-color: $space-grey-color;
    border: none;
    border: 1px solid $fossil-grey;
    color: $white-color;
  }
}
