.overrideCompleteProfileCard {
    width: 100%;
  .card {
    background: #778dff;
    height: 90px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    .link{
        text-decoration: underline;
        padding: 0px 5px;
    }
    .link:hover{
        cursor: pointer;
    }
  }
}
