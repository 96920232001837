@import "../../scss//variables";

.topHeader {
  .headerMain {
    min-height: auto;
    .welcomeUser {
      font-family: $font-Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-24;
      line-height: 29px;
      color: $text-black-color;
      text-align: left;
      margin: 0px;
      @media (max-width: 767px) {
        font-size: $font-size-20;
        margin-bottom: 10px;
      }
    }
    .navbarStyle {
      display: flex;
      justify-content: flex-start;
      gap: 30px;
    }

    .DrawerIcon {
      visibility: hidden;
    }
    @media only screen and (max-width: 600px) {
      .DrawerIcon {
        visibility: visible;
      }
    }
    .profilePic {
      cursor: pointer;
    }
  }
}

.header-over {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
  .header-box {
    background: transparent;
    color: $black-color;
    box-shadow: none;
    // padding: 10px 0px;
    // margin-bottom: 20px;
    .header-icon {
      font-family: monospace;
      font-weight: 700;
      letter-spacing: 0.3rem;
      color: inherit;
      text-decoration: none;
    }
    .pages-title {
      color: $black-color;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .light-logo {
      display: block;
    }
    .dark-logo {
      display: none;
    }
  }
}

.ClientSearchBox {
  min-width: 280px;
}
.menuIconMobile {
  // display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.topHeaderBox {
  @media (max-width: 767px) {
    padding: 0px 15px;
  }
  align-items: center;
  .rightBox {
    text-align: right;
    .iconRightBox {
      text-align: right;
    }
  }
}

[data-theme="dark"] {
  .header-over {
    .header-box {
      background: $black-color;
      color: $black-color;
      box-shadow: none;
      .header-icon {
        font-family: monospace;
        font-weight: 700;
        letter-spacing: 0.3rem;
        color: inherit;
        text-decoration: none;
      }
      .pages-title {
        color: $white-color;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .light-logo {
        display: none;
      }
      .dark-logo {
        display: block;
      }
    }
  }
}
