.conversationImage {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 20px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .ProfileImage {
    width: 24%;
    img {
      max-width: 85px;
      max-height: 90px;
      border-radius: 100%;
      border: 3px solid #eeeeee;
    }
  }
  .ProfileContent {
    height: auto !important;
    width: 74%;
    margin-left: 0px;
    .listDate {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #999999;
      text-align: right;
    }
    .profileDetailContent {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 10px;
      cursor: pointer;
      .profileDetail {
        padding-left: 20px;
        .userName {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #333333;
          margin: 0px 0px 5px 0px;
        }
        .msg {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #666666;
          margin: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 230px;
        }
      }
      .profileUnReadMsg {
        .unReadMessage {
          // padding: 5px;
          max-height: 20px;
          min-height: 20px;
          min-width: 20px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f44336;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .conversationImage {
    .ProfileContent {
      .profileDetailContent {
        .profileDetail {
          .msg {
            max-width: 149px;
          }
        }
      }
    }
  }
}
