@import "../../../scss//variables";
.overrideSearchJob{
    p{
        margin: 0;
        padding: 0;
        font-family: $font-Montserrat;
    }
    .font16{
        font-size: $font-size-16;
        color: #333333;
    }
    .font24{
        font-size: $font-size-24;
    }
    .font700{
        font-weight: 700;
    }
}