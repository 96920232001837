.overrideManaging {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    input {
      width: 80%;
      outline: none;
      border: none;
    }
    button {
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      svg {
        margin-bottom: -5px;
      }
    }
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }
}
// tr:nth-child(even) {
//   background-color: #f9f9f9;
// }

// tr:hover {
//   background-color: #e5e5e5;
// }
