.slider {
  .MuiSlider-rail{
    opacity: 1;
  }
  .MuiSlider-track{
    width: 0;
  }
  .MuiSlider-thumb {
    &[data-index="0"] {
      height: 12px;
      width: 12px;
      box-shadow: #0d8e6d;
      &::before {
        // color: #0d8e6d;
        background-color: #0d8e6d;
      }
    }
    &[data-index="1"] {
      height: 12px;
      width: 12px;
      &::before {
        background-color: #8e0d0d;
      }
    }
  }
}
