.quill {
  height: 100%;
  width: 100%;
  // max-width: 620px;

  .ql-toolbar {
    border-radius: 8px 8px 0 0;
  }
  .ql-container {
    border-radius: 0px 0px 8px 8px;
  }
}
p img {
  width: 100%;
  height: 100%;
}

.CommunityCardContainer {
  .ql-video {
    height: 100%;
    width: 100%;
    min-height: 350px;
  }
}
